import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Bau = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>BAU o BAUS significa “construcción” en Alemán.&nbsp;</Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
