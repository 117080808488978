import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const NoShowQuotation = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Si tienes problemas para visualizar la cotización, realiza los
        siguientes estos pasos para detectar los posibles inconvenientes.&nbsp;
      </Info>
      <Info>1. Comprueba si tienes conexión a internet.</Info>
      <Info>
        2. Si estás desde una red empresarial, verifica las restricciones de red
        con el Proxy o Firewalle.&nbsp;
      </Info>
      <Info>
        3. Utiliza los siguientes navegadores:&nbsp;Chrome, FIrefox Mozzilla y
        Opera.&nbsp;
      </Info>
      <Info>
        Si luego de verificar estos pasos sigues teniendo problemas para
        visualizar la cotización, contáctanos a través de el correo
        servicioalcliente@bimbau.co y resolveremos tu inconveniente lo más
        pronto posible.&nbsp;
      </Info>
    </Flex>
  );
};
