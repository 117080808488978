import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Url = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Más conocido por las siglas URL, del inglés{" "}
        <em>Uniform Resource Locators</em> una dirección web o de internet única
        y específica que se asigna a todos los recursos que están en internet
        (imágenes, sitios web, artículos, textos, videos, fotos) para que puedan
        ser localizados fácilmente por los usuarios en el navegador de
        internet.&nbsp;
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
