import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const UpdateProductss = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        <img
          alt="actualizar_informacion"
          src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/actualizar_informacion.jpg"
          width={isMobile ? "100%" : "688px"}
        />
        En este instructivo aprenderás a
        <strong> actualizar tus productos en BIMBAU.</strong> Así tendrás más
        posibilidades de vender y de ganar licitaciones
      </Info>
      <Title>Actualización masiva</Title>
      <ul>
        <li>
          <Info>
            Ingresa a bimbau.co, accede con&nbsp;<strong>usuario</strong>&nbsp;y
            <strong>&nbsp;contraseña</strong>.
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Productos </strong>y luego selecciona{" "}
            <strong>Actualizar productos.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Descarga la<strong> Plantilla de productos</strong> que tienes en la
            BIMBAU.
            <blockquote>
              <Info>
                Ten en cuenta que para modificar la plantilla debes tener
                instalado <strong>Excel </strong>en tu computador.
              </Info>
            </blockquote>
          </Info>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Modifica los productos que necesites en un plantilla.
          </Info3>
        </li>
      </ul>
      <blockquote>
        <Info3 style={{ textDecoration: "none" }}>
          <strong>
            ⚠️NOTA IMPORTANTE:
            <em>
              {" "}
              Recuerda que desde la actualización masiva puedes solo modificar
              los precios, la moneda y el transporte
            </em>
          </strong>
        </Info3>
      </blockquote>
      <ul>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            <em>
              <strong>&nbsp;Regresa </strong>
            </em>
            &nbsp;a bimbau.co, carga de nuevo la{" "}
            <strong>plantilla de productos</strong> y listo.&nbsp;
          </Info3>
        </li>
      </ul>
      <br></br>
      <Title>Actualización Individual</Title>
      <Info>
        Mantén siempre actualizado el <strong>precio de tus productos </strong>y
        las condiciones comerciales para que aumentes las posibilidades de
        venta.
      </Info>
      <ul>
        <li>
          <Info>
            Ingresa a<strong> bimbau.co</strong>, accede con&nbsp;
            <strong>usuario</strong>&nbsp;y<strong>&nbsp;contraseña</strong>.
          </Info>
        </li>
        <li>
          <Info>
            Dirígete a <strong>Productos </strong>que encuentras en la barra
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Escoge el producto que deseas actualizar y desde
            <strong> Acciones</strong> selecciona <strong>Editar.</strong>
          </Info>
        </li>
        <li>
          <Info>
            <strong>Actualiza el producto </strong>y finaliza haciendo clic en
            <strong> Guardar los cambios.</strong>
          </Info>
        </li>
      </ul>
      <Info>
        <blockquote>
          <strong>⚠️NOTA IMPORTANTE: </strong>Desde la actualización individual
          puedes modificar todos los campos de tu productos.
        </blockquote>
      </Info>
      <Title style={{ textAlign: "center" }}>
        Y ¡Listo! Ya tienes tus productos actualizados.&nbsp;
      </Title>
      <Info>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="adjudicate-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/9vcoAJrZQVk?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
