import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateProject = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <strong>
          <img
            alt="Como-crear-proyecto-1"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/Como-crear-proyecto-1.jpg"
            width={isMobile ? "100%" : "688px"}
          />
        </strong>
      </Title>
      <Title>
        <strong>
          Crea tus proyectos en BIMBAU y empieza a hacer las compras para tus
          obras.&nbsp;
        </strong>
      </Title>
      <Info>
        <strong>Sigue estos pasos para crear un proyecto.</strong>
      </Info>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              href="http://bimbau.co/"
              rel="noopener"
              style={{ color: theme.colors.secondary }}
            >
              <em>
                <strong> bimbau.co</strong>
              </em>
            </a>
            &nbsp;e inicia sesión con <strong>Usuario y Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Proyectos</strong> que encuentras en la esquina
            superior izquierda.
          </Info>
        </li>
        <li>
          <Info>
            Selecciona<strong> Crear proyecto.</strong>
          </Info>
        </li>
      </ul>
      <Info>
        Para realizar la creación del proyecto completa la información básica de
        la obra; con esta información podrás realizar compras, negociaciones,
        pedidos y más.&nbsp;
      </Info>
      <blockquote>
        <Info>
          <strong>📎TIPS: </strong>Ten presente que deberás estar primero
          asociado a una empresa con un perfil de Administrador.&nbsp;
        </Info>
      </blockquote>
      <Info>
        Cuando hayas completado la información de tu obra o proyecto de
        construcción, selecciona el Centro de costos.{" "}
        <strong>
          <em>
            Los centros de costos te permiten establecer los montos en las
            diversas fases o etapas del proyectos.&nbsp;
          </em>
        </strong>
      </Info>
      <Info2 style={{ textAlign: "center" }}>
        <strong>Para cargar masivamente tu</strong>
        <strong>
          s <em>Centros de costos</em> ingresa aquí&nbsp;
        </strong>
      </Info2>
      <Title>
        <strong>Solicitantes</strong>
      </Title>
      <Info>
        Los solicitantes o compradores son aquellos colaboradores del proyecto
        que realizan las compras de materiales y servicios para obra: como los
        residentes, directores, maestros de obras, almacenistas y demás.
      </Info>
      <ul>
        <li>
          Ingresa todos los{" "}
          <Info3 style={{ textDecoration: "underline" }}>Solicitantes </Info3>
          del proyecto escribiendo su{" "}
          <Info3 style={{ textDecoration: "underline" }}>
            nombre en el buscador
          </Info3>{" "}
          y luego selecciona el signo +, cuando hayas terminado de escogerlos
          selecciona{" "}
          <Info3 style={{ textDecoration: "underline" }}>Crear proyecto</Info3>
          <Info3 style={{ textDecoration: "underline" }}></Info3>
        </li>
      </ul>
      <Info2 style={{ textAlign: "center" }}>
        <em>
          <img
            alt="Cómo crear un proyecto en BIMBAU"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/C%C3%B3mo%20crear%20un%20proyecto%20en%20BIMBAU.gif"
            width={isMobile ? "100%" : "688px"}
          />
        </em>
      </Info2>
      <Title style={{ textAlign: "center" }}>
        <strong>¡Listo, ya crea</strong>
        <strong>ste tu proyecto!</strong>
      </Title>
      <Info>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        <strong>
          ¡Para más detalles acerca de cómo crear un proyecto mira este video!
        </strong>
      </Info>
      <Info style={{ textAlign: "center" }}>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="create-project"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/Bw_BqM35jHs?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
