import React from "react";
import { Flex } from "../../styles/common";
import { FooterWrapper, Links, WrapperLink, WrapperLinks } from "./styles";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";

export const Footer = () => {
  const social = [
    {
      icon: facebook,
      url: "https://www.facebook.com/bimbau.co",
    },
    {
      icon: instagram,
      url: "https://www.instagram.com/bimbau.co/",
    },
    {
      icon: linkedin,
      url: "https://es.linkedin.com/company/bimbau",
    },
  ];

  const links = [
    { label: "Aviso Privacidad", url: "https://www.bimbau.co/privacidad.html" },
    {
      label: "Política de tratamiento de datos",
      url: "https://www.bimbau.co/tratamiento.html",
    },
    { label: "Términos y condiciones", url: "https://www.bimbau.co/tyc.html" },
    {
      label: "Superintendencia de insdustria y Comercio SIC",
      url: "https://www.sic.gov.co/",
    },
  ];

  return (
    <FooterWrapper>
      <Flex gap="1rem" align="start">
        {social.map((ele, indx) => (
          <a href={ele.url}>
            <img
              key={indx}
              alt="social"
              src={ele.icon}
              style={{ cursor: "pointer" }}
            />
          </a>
        ))}
      </Flex>
      <WrapperLinks>
        {links.map((ele, indx) => (
          <WrapperLink>
            <Links key={indx} href={ele.url}>
              {ele.label}
            </Links>
          </WrapperLink>
        ))}
      </WrapperLinks>
    </FooterWrapper>
  );
};
