import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2 } from "../../styles/common";
import { theme } from "../../styles/theme";

export const RequestBimObject = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info2>
        Para solicitar la modelación de un objeto BIM, debes estar registrado en
        bimbau.co
      </Info2>
      <ul>
        <li>
          <Info>
            <strong>Ingresa</strong>&nbsp;a&nbsp;
            <a
              style={{ color: theme.colors.secondary }}
              href="http://www.bimbau.co/"
            >
              bimbau.co
            </a>
            &nbsp;y accede con&nbsp;
            <strong>usuario y contraseña</strong>.
          </Info>
        </li>
        <li>
          <Info>
            Desde el<strong> buscador,</strong> busca el producto que necesitas
            que <strong>modelemos en BIM.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Una vez dentro del producto, selecciona{" "}
            <strong>Solicitar objeto BIM.</strong>
          </Info>
        </li>
        <li>
          <Info>
            <strong>Completa</strong>&nbsp;el formulario<strong> , </strong>haz
            clic en<strong> Crear solicitud y listo.</strong>
          </Info>
        </li>
      </ul>
      <p>
        <img
          alt="Solicitar Objeto BIM"
          class="hs-image-align-none"
          src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Solicitar%20Objeto%20BIM.gif"
          width={isMobile ? "100%" : "688px"}
        />
      </p>
      <p>&nbsp;</p>
    </Flex>
  );
};
