import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Bimbau = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        BIMBAU, nace de la necesidad de digitalizar la cadena de abastecimiento,
        mejorando los tiempos y costos en los procesos para constructores y
        proveedores, con un plus muy importante, nuestro Marketplace de Objetos
        BIM que&nbsp; integra al diseñador como otro actor fundamental en la
        cadena de valor.&nbsp;
      </Info>
    </Flex>
  );
};
