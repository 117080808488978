import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ResponseQuotation = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fc155f8cff47e00160be532file-bTJoNdcWB3.png"
      />
      <Title>
        Aprende a responder cotizaciones&nbsp;en unos cuantos pasos.
      </Title>
      <Info>
        Para responder una licitación en BIMBAU debes estar registrado como
        VENDEDOR.
      </Info>
      <br></br>
      <Info>
        <strong>
          Ten presente que las cotizaciones únicamente&nbsp;las recibirás al
          correo que asociaste a tu perfil en BIMBAU.&nbsp;
        </strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fbec03a4cedfd001611099ffile-Kc9Jot5TC9.png"
      />
      <Title>¿Cómo responder una cotización?&nbsp;</Title>
      <Info>
        1.{" "}
        <a
          style={{ color: theme.colors.secondary }}
          href="https://app.bimbau.co/autenticacion"
        >
          Ingresa&nbsp;
        </a>
        al correo que asociaste a tu cuenta en{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co">
          BIMBAU
        </a>{" "}
        y selecciona el e-mail de la invitación a participar en la cotización.
      </Info>
      <Info>
        2. Una vez dentro, haz clic en{" "}
        <strong>&nbsp;"Enviar cotización"</strong>
      </Info>
      <Info>
        3. En la cotización verás la información del requerimiento, léela
        detalladamente y consulta los documentos adjuntos si aplica.
      </Info>
      <Info>
        4. En la sección de "Tu repuesta" completa los siguientes datos.
      </Info>
      <Info>
        <strong>Documentos adjuntos:</strong> Adjuntar si deseas algún archivo
        para complementar la cotización.
      </Info>
      <Info>
        <strong>Observaciones:</strong> Agrega alguna información si la
        consideras necesario.
      </Info>
      <Info>
        <strong>Total propuesta económica:</strong> Determina tu oferta para los
        requerimientos.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fc15c50cff47e0017d36231file-V5o2yEqlTk.png"
      />
      <br></br>
      <br></br>
      <Info>
        5. Cuando hayas completado todos lo campos selecciona{" "}
        <strong>ENVIAR</strong> y listo.
      </Info>
      <Title style={{ textAlign: "center" }}>
        Ya habrás enviado la cotización.
      </Title>
      <Info style={{ textAlign: "center" }}>
        Recuerda que al enviar la cotización estás aceptando los{" "}
        <a
          style={{ color: theme.colors.secondary }}
          href="https://bimbau.s3.amazonaws.com/Resources/Autorizaci%C3%B3n+para+el+manejo+de+datos+y+uso+de+logos.pdf"
        >
          {" "}
          términos y condiciones
        </a>{" "}
        de BIMBAU.
      </Info>
      <Info style={{ textAlign: "center" }}>
        ¡Mira este video y conoce el paso a paso para contestar una Cotización!
      </Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: " 100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="response-quotation"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/o7q1VuOpY8w?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
