import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";
import { theme } from "../../styles/theme";

export const Rol = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Un rol es una identificación&nbsp;que te permite interactuar y acceder a
        las diferentes funcionalidades de{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co">
          bimbau.co.
        </a>{" "}
        En BIMBAU existen dos tipos de roles predeterminados, el de{" "}
        <strong>Administrador</strong> que tiene acceso a todas las
        funcionalidades de la plataforma y el de <strong>Nuevo usuario</strong>{" "}
        que tiene accesos limitados; con ellos podrás planificar las acciones de
        tu equipo y tener control de tus procesos estratégicos.&nbsp;
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
