import { Col, Row } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setQuestion, setService } from "../../store/common";
import {
  ServiceMessage,
  ServiceQuestion,
  ServiceTitle,
} from "../../styles/common";
import { theme } from "../../styles/theme";

export const Services = ({ title, message, questions }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoQuestion = (link, name, question) => {
    navigate(link);
    dispatch(setService(name));
    dispatch(setQuestion(question));
  };

  return (
    <div>
      <ServiceTitle>{title}</ServiceTitle>
      <ServiceMessage>{message}</ServiceMessage>
      <Row style={{ width: "100%" }}>
        {questions.map((ele, indx) => (
          <Col md={24} key={indx}>
            <div
              style={{
                height: isMobile ? "max-content" : "30px",
                padding: isMobile ? "0 0 10px 0" : "0 0 6px 0",
              }}
            >
              <ServiceQuestion
                onClick={() =>
                  handleGoQuestion(ele.link, ele.service, ele.label)
                }
              >
                {ele.label}
              </ServiceQuestion>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
