import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const BuyBimbau = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <img
          alt="como-comprar-en-bimbau-v2"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/como-comprar-en-bimbau-v2.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Title>
      <Title>
        Sigue estos pasos para comprar los materiales de construcción para tu
        obra.&nbsp;
      </Title>
      <Info>
        &nbsp;Ten presente que para comprar en<strong> bimbau.co</strong>,
        tienes que haber creado un proyecto y tener un rol de
        <strong> comprador.</strong>
      </Info>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              href="http://bimbau.co/"
              rel="noopener"
              style={{ color: theme.colors.secondary }}
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>
            &nbsp;con<strong>&nbsp;Usuario</strong>&nbsp;y
            <strong>&nbsp;Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Selecciona el <strong>proyecto </strong>para el cuál vas a realizar
            la compra.
          </Info>
        </li>
        <li>
          <Info>
            Navega por el <strong>catálogo</strong> y digita en el{" "}
            <strong>buscador</strong> los productos que necesitas comprar.
          </Info>
        </li>
        <li>
          <Info>
            Agrega al <strong>carrito tu pedido</strong>, y verifica que todas
            las cantidades estén correctas.<br></br>
            <blockquote>
              <strong>⚠️IMPORTANTE⚠️ </strong>Si haz establecido
              <strong>&nbsp;Centros de costos </strong>para tus proyectos,
              puedes asignarlos desde el carrito a cada material.
            </blockquote>
          </Info>
        </li>
        <li>
          <Info>
            Al finalizar, selecciona<strong> Hacer pedido </strong>y
            listo.&nbsp;
          </Info>
        </li>
      </ul>
      <blockquote>
        <Info>
          📍Recuerda que tu pedido tendrá que ser aprobado por el o los{" "}
          <em>
            <strong>aprobadores </strong>
          </em>
          de tu proyecto; luego de la aprobación, BIMBAU genera tu orden de
          compra para tu proveedor y despachará tu pedido.
        </Info>
      </blockquote>
      <Info>
        <strong>📎TIPS</strong>: Revisa la sección de pedidos en tu cuenta de
        BIMBAU, para conocer el estado de tu solicitud.
      </Info>
      <Info style={{ textAlign: "center" }}>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="adjudicate-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/nlbNpZonpfU?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
