import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Buyer = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Un comprador es un perfil en BIMBAU encargado de hacer las solicitudes
        de pedido de insumos y servicios que se necesiten para los diferentes
        proyectos de una constructora.
      </Info>
    </Flex>
  );
};
