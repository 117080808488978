import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateAgreement = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        <strong>
          <img
            alt="Como-crear-negociación (1)"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/Como-crear-negociaci%C3%B3n%20(1).jpg"
            width={isMobile ? "100%" : "688px"}
          />
          <br></br>
          Las negociaciones
        </strong>{" "}
        son el acuerdo entre las partes involucradas en una relación comercial
        para establecer un precio equitativo de algún producto o servicio por un
        tiempo estipulado que, beneficie a ambos. Antes de crear una{" "}
        <Info3>
          <strong>Negociación</strong>
        </Info3>{" "}
        debes crear una
        <strong>
          <Info3> Cobertura de la negociación, </Info3>
        </strong>
        para hacerlo, sigue estos pasos.
      </Info>
      <Title>👍 Crea una cobertura de la Negociación</Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            e inicia sesión con <strong>Usuario </strong>y
            <strong> Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a{" "}
            <strong>
              <Info3>Negociación</Info3>
            </strong>{" "}
            que encuentras en la parte superior.
          </Info>
        </li>
        <li>
          <Info>
            Una vez dentro, dirígete a{" "}
            <Info3>
              <strong>Coberturas de la negociación</strong>{" "}
            </Info3>
            que encuentras en la barra superior.
          </Info>
        </li>
        <li>
          <Info>
            Haz clic en{" "}
            <strong>
              <Info3>Crear cobertura.</Info3>
            </strong>
          </Info>
        </li>
        <li>
          <Info>
            Selecciona el país de la cobertura y haz clic en{" "}
            <Info3>Crear cobertura.</Info3>
            <Info3></Info3>
          </Info>
          <blockquote>
            <Info>
              <strong>📎TIPS: </strong>Ten presente que puedes seleccionar todos
              los estados o provincias del país, si quieres segmentar la
              cobertura, una vez creada edita desde Acciones y escoge las
              ciudades y provincias especificas de la cobertura.
            </Info>
          </blockquote>
          <Info style={{ textAlign: "center" }}>
            <strong>&nbsp; ¡Ya creaste tu cobertura!</strong>
          </Info>
        </li>
      </ul>
      <Info>
        <strong>
          <img
            alt="como crear una negociacion-1"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20crear%20una%20negociacion-1.gif"
            width={isMobile ? "100%" : "688px"}
          />
        </strong>
      </Info>
      <Title>Creemos una negociación en BIMBAU</Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            y accede con{" "}
            <strong>
              <Info3>Usuario</Info3> y <Info3>Contraseña.</Info3>
            </strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a{" "}
            <strong>
              <Info3>Negociación</Info3>{" "}
            </strong>
            que encuentras en la parte superior.
          </Info>
        </li>
        <li>
          <Info>
            Haz clic en&nbsp;
            <Info3>
              <strong>Crear negociación</strong>.
            </Info3>
          </Info>
          <Title>
            <img
              alt="como crear una negociacion-2"
              src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20crear%20una%20negociacion-2.gif"
              width={isMobile ? "100%" : "688px"}
            />
          </Title>
          <Title>
            <Info3>
              <strong>Información general</strong>
            </Info3>
          </Title>
          <Info>
            <strong>¡Comencemos!</strong>
          </Info>
        </li>
        <li>
          <Info>
            <strong> </strong>Escoge primero si los productos que estarán dentro
            de la <strong>Negociación</strong> son propios o pertenecen a una
            empresa o proveedor invitado.
          </Info>
        </li>
        <li>
          <Info>
            Escribe el{" "}
            <strong>
              <Info3>Nombre de la empresa </Info3>
            </strong>
            y selecciónala para realizar la negociación, además agrega&nbsp; a
            un <Info3>Responsable</Info3> que se encargará de administrar y
            aprobar la negociación.
          </Info>
        </li>
      </ul>
      <Info>
        Determina, luego si la negociación será visible para las empresas
        implicadas o para todo el publico.Al terminar, selecciona&nbsp;
        <Info3>
          <strong>continuar</strong>
        </Info3>
        <strong> </strong>para ir a la siguiente parte y seguir con la
        información de la negociación.
      </Info>
      <Title>
        <strong>Información de la negociación</strong>
      </Title>
      <ul>
        <li>
          <Info>
            Determina el rango de tiempo donde primará la Negociación; digitando
            la fecha de inicio y fecha de fin.{" "}
            <strong>
              Ten en cuenta que&nbsp;podrás modificar estas fechas cuando lo
              desees.&nbsp;
            </strong>
            &nbsp;
          </Info>
        </li>
        <li>
          <Info>
            Selecciona la <Info3>Moneda</Info3> para esta negociación y la{" "}
            <Info3>Cobertura</Info3> donde se llevará a cabo.
          </Info>
        </li>
        <li>
          <Info>
            Escribe las<em> </em>
            <Info3>Condiciones de pago</Info3>
            <em> </em>y escoge el <Info3>Proyecto</Info3> o los Proyectos de la
            negociación.&nbsp;
          </Info>
        </li>
        <li>
          <Info>
            Por último, escribe las{" "}
            <Info3>Condiciones comerciales.&nbsp;</Info3>
            <Title>
              <strong>Productos de la negociac</strong>
              <strong>ión</strong>
            </Title>
          </Info>
        </li>
        <li>
          <Info>
            Descarga la plantilla de productos y modifica el precio al nuevo
            precio de la negociación.
          </Info>
        </li>
        <li>
          <Info>
            Al terminar, carga la plantilla de productos y haz clic en{" "}
            <strong>Crear negociación.&nbsp;</strong>
          </Info>
        </li>
      </ul>
      <Info2 style={{ textAlign: "center" }}>
        <strong>¡Listo! Tu Negociación de precio ya estará creada.</strong>
      </Info2>
      <Title>
        <strong>👍 Edita las negociaciones</strong>
      </Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            e inicia sesión con <strong>Usuario </strong>y
            <strong> Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Negociación</strong> que encuentras en la parte
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Escoge<strong>&nbsp;</strong>la<strong> Negociación </strong>que
            necesitas modificar y luego selecciona<strong> editar.</strong>
          </Info>
        </li>
      </ul>
      <blockquote>
        <Info>
          📍 Ten en cuenta que solo{" "}
          <em>
            <strong>p</strong>
            <strong>uedes editar</strong>
          </em>{" "}
          las negociaciones en estado
          <strong>
            {" "}
            Pendiente o Rechazada y únicamente&nbsp;el usuario que crea la
            negociación puede editarla.&nbsp;&nbsp;
          </strong>
        </Info>
      </blockquote>
      <ul>
        <li>
          <Info>
            <strong>Edita la información</strong> y luego guarda los
            cambios.&nbsp;
          </Info>
        </li>
      </ul>
      <blockquote>
        <Info>
          <strong>⚠️IMPORTANTE⚠️</strong> Todas las personas involucradas en la
          negociación recibirán una notificación al correo con los cambios que
          realizaste.
        </Info>
      </blockquote>
      <Info>
        <img
          alt="Editar Negociacion GIF-1"
          class="hs-image-align-none"
          src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Editar%20Negociacion%20GIF-1.gif"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Title>
        <strong>👍Duplica las negociaciones</strong>
      </Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            e inicia sesión con <strong>Usuario </strong>y
            <strong> Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Negociación</strong> que encuentras en la parte
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Escoge<strong>&nbsp;</strong>la<strong> Negociación </strong>que
            necesitas duplicar y haz clic en <strong>Acciones.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Selecciona <strong>Duplicar negociación</strong> y listo.
          </Info>
          <img
            alt="Duplicar Negociacion GIF-1"
            src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Duplicar%20Negociacion%20GIF-1.gif"
            width={isMobile ? "100%" : "655px"}
          />
        </li>
      </ul>
      <Info>&nbsp;</Info>
      <blockquote>
        <Info>
          <strong>⚠️IMPORTANTE⚠️ </strong>Si estás duplicando una negociación
          activa para actualizar la vigencia o los precios, recuerda inactivar
          la anterior.
        </Info>
      </blockquote>
    </Flex>
  );
};
