import styled from "styled-components";
import { theme } from "../../styles/theme";

export const FooterWrapper = styled.div`
  display: flex;
  background: ${theme.colors.white}
  height: 131.4px;
  text-align: center;
  margin: 0 165.667px;
  padding: 1.15rem 0 1.5em;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    margin: 0 50px;
  }
`;

export const Links = styled.a`
  font-family: Montserrat;
  font-size: ${theme.fonts.size.sm};
  color: ${theme.colors.gray};
  line-height: 16px;
  text-decoration: none;
  text-align: -webkit-left;

  :hover {
    text-decoration: underline;
  }
`;

export const WrapperLinks = styled.div`
  display: flex;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const WrapperLink = styled.div`
  display: flex;
  width: 104.5px;
  height: 60px;
  padding: 16px;

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    height: 20px;
  }
`;
