import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";
import { theme } from "../../styles/theme";

export const Conditions = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Las condiciones comerciales son utilizadas para que
        el&nbsp;&nbsp;proveedor establezca aclaraciones acerca de un producto o
        servicio.&nbsp;Estas condiciones determinan los fletes, fechas de pago,
        IVA y todos los detalles comerciales para concretar una venta informada.
      </Info>
      <Info>
        <strong>Algunas condiciones comerciales son:</strong>
        <br></br>- Precio con o sin IVA <br></br>- Forma de pago <br></br>-
        Vigencia <br></br>- Cantidades mínimas de despacho <br></br>-
        Condiciones de entrega de mercancía <br></br>- Si incluye transporte o
        no <br></br>- Cancelaciones <br></br>- Devoluciones
      </Info>
      <Info>
        <img
          alt=""
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images60009f371c64ad47e4b6f5dbfile-aoZnW3DVka.png"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
