import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const PurchaseOrders = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Es un documento que emite el comprador solicitando insumos o servicios
        al proveedor.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
