import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const UpdateProducts = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>Modifica o edita la información de tu productos</Title>
      <Info>
        Para editar la información relacionada de tus productos sigue estos
        pasos.
      </Info>
      <Info>
        1. Ingresa con usuario y contraseña a&nbsp;
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co/">
          bimbau.co.
        </a>
      </Info>
      <Info>
        2. Dirígete a Administración que encuentras en la esquina lateral
        derecha.
      </Info>
      <Info>
        3. Luego, ve a&nbsp;<strong>Productos.</strong>&nbsp;Desde este espacio
        podrás editar cada uno de tus productos de forma individual o
        masiva.&nbsp;
      </Info>
      <Info2>
        Los catálogos de productos de BIMBAU son 100% administrados por cada
        proveedor.
      </Info2>
    </Flex>
  );
};
