import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Quotation = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Es un módulo diseñado para solucionar esa necesidades urgentes de
        insumos y servicios que requieran las constructoras,&nbsp; a través, de
        este módulo, pueden solicitar de manera fácil y ágil los requerimientos
        para lo proyectos y comprarlos en el tiempo que necesite la obra. Está
        pensando principalmente para compras exprés.&nbsp;
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
