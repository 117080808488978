import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Plantillas = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Las plantillas&nbsp;generalmente son archivos de Excel que permiten
        completar alguna información que se necesite suministrar o ingresar a la
        plataforma. Estas plantillas te permiten cargar productos, ingresar
        centros de costos y más…
      </Info>
      <Info>
        <strong>
          Algunas de las plantillas que podrás encontrar en BIMBAU son:
        </strong>
        <br></br>- Plantilla para carga de productos <br></br>- Plantilla para
        crear una licitación <br></br>- Plantilla para responder una licitación{" "}
        <br></br>- Plantilla para crear centros de costos de tus proyectos de
        construcción
      </Info>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
