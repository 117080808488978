import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const AdminRequest = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        <img
          alt="Como-administrar-pedidos"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Como-administrar-pedidos.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>
        <strong>Las Pedidos&nbsp;</strong>te permiten gestionar, administrar y
        consultar todos las compras que se realizan para tus proyectos de
        construcción.&nbsp; Con este artículo aprenderás a hacerlo.&nbsp;
      </Info>
      <Title>Gestión y administración de pedidos&nbsp;</Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              href="http://bimbau.co/"
              rel="noopener"
              style={{ color: theme.colors.secondary }}
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>
            &nbsp;y ingresa con&nbsp;
            <Info3 style={{ textDecoration: "underline" }}>Usuario&nbsp;</Info3>
            y&nbsp;
            <Info3 style={{ textDecoration: "underline" }}>Contraseña.</Info3>
          </Info>
        </li>
        <li>
          Ve a <Info3 style={{ textDecoration: "underline" }}>Pedidos</Info3>{" "}
          que encuentras en la parte superior.
        </li>
      </ul>
      <Info>
        En la sección de{" "}
        <Info3 style={{ textDecoration: "underline" }}>Pedidos</Info3>{" "}
        encuentras la información de todos los pedidos realizados para el
        abastecimiento de tus proyectos. Para consultar los detalles desde{" "}
        <Info3 style={{ textDecoration: "underline" }}>Historial</Info3>{" "}
        dirígete a{" "}
        <Info3 style={{ textDecoration: "underline" }}>Acciones</Info3>,&nbsp;
        selecciona el ícono de <strong>Ver</strong> y desde ese espacio consulta
        la información del pedido.
      </Info>
      <blockquote>
        <Info>
          <em>
            <strong>📎</strong>
          </em>
          <strong>TIPS:</strong>
          <em>
            <strong> </strong>Ten presente que antes de Aprobar o Rechazar un
            pedido debes conocer la información y los detalles del mismo.
          </em>
        </Info>
      </blockquote>
      <Info>
        <em>
          <img
            alt="como administrar pedidos"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20administrar%20pedidos.gif"
            width={isMobile ? "100%" : "688px"}
          />
        </em>
      </Info>
      <Title>Aprobación de pedidos</Title>
      <ul>
        <li>
          Ve a la pestaña <strong>Pendiente de aprobación</strong> que
          encuentras en la barra superior.&nbsp;
        </li>
        <li>
          Selecciona el <strong>Pedido</strong> haciendo clic en la casilla de
          selección.
        </li>
      </ul>
      <Info>
        En la barra superior haz clic en<em> </em>
        <strong>Aprobar </strong>y listo. Ya aprobaste tu pedido.
      </Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="admin-requests"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/z5Do6JegwaI?start=2&amp;feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
      <Title>Rechazo de pedidos</Title>
      <ul>
        <li>
          <Info>
            Ve a la pestaña{" "}
            <strong>
              Pendiente de aprobació
              <Info3 style={{ textDecoration: "underline" }}>n</Info3>
            </strong>{" "}
            que encuentras en la barra superior.&nbsp;
          </Info>
        </li>
        <li>
          <Info>
            Selecciona el <strong>Pedido </strong>haciendo clic en la casilla de
            selección.
          </Info>
        </li>
        <li>
          <Info>
            En la barra superior haz clic en <strong>Rechazar </strong>y listo.
            Ya rechazaste un pedido.
          </Info>
        </li>
      </ul>
    </Flex>
  );
};
