import React from "react";
import { questions } from "../../utilities/questions";
import { Services } from "../../components/Services";

export const Questions = () => {
  const frecQuestions = questions.filter(
    (ele) => ele.service === "preguntas-frecuentes"
  );

  return (
    <Services
      title={"Preguntas frecuentes"}
      message={
        "Encuentra aquí las preguntas más frecuentes que realizan los usuarios en BIMBAU."
      }
      questions={frecQuestions}
    />
  );
};
