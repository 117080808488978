import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const UploadProducts = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        <img
          alt="Portada4-V"
          src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Portada4-V.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>
        En este instructivo aprenderás a cargar tus productos y así aumentar tus
        ventas con BIMBAU.
      </Info>
      <Info>
        Ten presente que para cargar productos debes estar registrado en BIMBAU
        y tener al menos una categoría a la cual pertenezcan tus productos.
      </Info>
      <Info>
        Para cargar productos debes <strong>crear las coberturas.</strong> Estas
        determinan geográficamente la zonas y disponibilidad de los productos y
        servicios que están en el catálogo.&nbsp;
      </Info>
      <Info>
        <strong>
          ⚠️NOTA IMPORTANTE:
          <em>
            {" "}
            Al crear las coberturas BIMBAU te arrojará un ID, es decir un
            identificador único que usarás para llenar los campos de la
            plantilla de carga de productos.
          </em>
        </strong>
      </Info>
      <Info style={{ textAlign: "center" }}>
        <br></br>
        <strong>
          <a
            style={{ color: theme.colors.secondary }}
            href="https://ayuda.bimbau.co/c%C3%B3mo-crear-una-cobertura-en-bimbau"
            rel="noopener"
          >
            👉Descubre cómo crear una cobertura aquí👈
          </a>
        </strong>
      </Info>
      <Title>
        <img
          alt="como crear cobertura-2"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20crear%20cobertura-2.gif"
          width={isMobile ? "100%" : "688px"}
        />
        <br></br>
        <br></br>Empecemos con la carga de tus productos.
      </Title>
      <ul>
        <li>
          <Info>
            Ingresa a bimbau.co, accede con <strong>usuario</strong> y
            <strong> contraseña</strong>.
          </Info>
        </li>
        <li>
          <Info>
            Selecciona <strong>Soy Vendedor</strong> como tu tipo de perfil.
          </Info>
        </li>
        <li>
          <Info>
            Elige a tu empresa o regístrala para poder cargar tu catálogo.
          </Info>
        </li>
        <li>
          <Info>
            Dirígete a la esquina superior derecha y selecciona{" "}
            <strong>Productos.</strong>
          </Info>
        </li>
        <li style={{ textAlign: "left" }}>
          <Info>
            Haz clic luego en<strong> Cargar productos </strong>y dirígete a las
            pestaña <strong>Carga de productos</strong>
            <br></br>Una vez estés en el menú, descarga la plantilla .CSV.
            <br></br>
            <br></br>
          </Info>
          <Title>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;👉
            <a
              style={{ color: theme.colors.secondary }}
              href="https://bimbau.s3.amazonaws.com/Provider/Dise%C3%B1o/bimbau_plantilla_producto+(40).csv"
              rel="noopener"
            >
              También puedes descárgala aquí
            </a>
            👈
          </Title>
          <br></br>
          <Title style={{ textAlign: "left" }}>
            Diligencia la plantilla de carga de productos en 3 pasos.
          </Title>
        </li>
      </ul>
      <Info style={{ textAlign: "left" }}>
        <strong>
          <em>&nbsp;</em>
        </strong>
        <Info3 style={{ textDecoration: "none" }}>
          Antes de diligenciar la plantilla, creamos para ti un archivo con la
          información detallada de cada campo y los caracteres admitidos por
          cada uno. Consúltala para resolver todas la dudas que tengas al
          momento de llenar.{" "}
          <strong>
            <a
              style={{ color: theme.colors.secondary }}
              href="https://docs.google.com/spreadsheets/d/1IA0CRfUrUkx5ccaQbWgATXt5RogXRjXJ/edit#gid=1491104742"
              rel="noopener"
            >
              👉Consúltala aquí👈
            </a>
          </strong>
        </Info3>
      </Info>
      <Info>
        <br></br>
        <strong>📎TIP</strong>
        <em>: </em>
        <em>
          Para diligenciar y llenar todos los campos de la plantilla debes tener
          previamente la información detallada de tus productos, esta
          información te servirá para diligenciar de manera más rápida y fácil
          los campos.
        </em>
      </Info>
      <Title>Completemos los campos en 3 pasos.</Title>
      <Info>
        <br></br>
        <strong>Paso 1: Campos obligatorios</strong>
      </Info>
      <Info>
        <img
          alt="Capture-1 (1)"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Capture-1%20(1).png"
          width={isMobile ? "100%" : "688px"}
        />
        <br></br>
        <strong>Paso 2: Campos complementarios.</strong>
      </Info>
      <Info>
        <strong>
          <img
            alt="Capture-2"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/Capture-2.png"
            width={isMobile ? "100%" : "688px"}
          />
        </strong>
      </Info>
      <Info>
        <strong>Paso 3: Campos adicionales</strong>
      </Info>
      <Info>
        Una vez terminado la carga de productos, regresa a{" "}
        <a
          style={{ color: theme.colors.secondary }}
          href="http://bimbau.co"
          rel="noopener"
        >
          <strong>bimbau.co</strong>
        </a>{" "}
        y carga la plantilla seleccionando<strong> Subir archivo. CSV. </strong>
        Verifica el estado de tu carga ingresando en el icono de{" "}
        <strong>Acciones; </strong>si la carga presenta error, corrígelo, puedes
        identificarlo en las casillas que te aparecen resaltadas en rojo.
      </Info>
      <Info>&nbsp;</Info>
      <Info>
        <img
          alt="Gif carga de productos"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Gif%20carga%20de%20productos.gif"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>
        Si la carga no presenta errores, envía tus productos a validación,
        seleccionando <strong>Enviar para aprobación. </strong>Cuando tu carga
        esté aprobada, te enviaremos un mensaje a tu correo electrónico
        notificando que tus productos se encuentran visibles en nuestro
        catálogo, y&nbsp;listo, ya tendrás
        <strong> cargados los productos en BIMBAU.</strong>
      </Info>
    </Flex>
  );
};
