import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Title, Info, Info2 } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ResetPasswordGlossary = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        Para restablecer&nbsp;tu contraseña en BIMBAU sigue estos pasos.&nbsp;
      </Title>
      <Info>
        1. Entra a{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co">
          {" "}
          bimbau.co
        </a>{" "}
        y dirígete a <strong>Ingresa.</strong>
      </Info>
      <Info>
        2. Selecciona <strong>¿Olvidaste la contraseña?</strong>&nbsp;y completa
        el campo el correo electrónico asociado a la cuenta.&nbsp;
      </Info>
      <Info>
        3. Selecciona el botón <strong>RECORDAR. Al correo&nbsp;</strong>
        electrónico llegará un mensaje con el enlace.
      </Info>
      <Info>
        4. Ve al enlace y reestablece la contraseña según las condiciones.&nbsp;
      </Info>
      <Info2>Y listo, ya tienes una nueva contraseña.</Info2>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
