import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Info3 } from "../../styles/common";
import { theme } from "../../styles/theme";

export const LibraryBimbau = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        <img
          alt="como-navegar-el-catalogo"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/como-navegar-el-catalogo.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>
        Para navegar el catálogo de BIMBAU no tienes que estar registrado,
        aunque para una mejor experiencia te recomendamos realizar el registro.
      </Info>
      <Info style={{ textAlign: "center" }}>
        <a
          href="https://ayuda.bimbau.co/c%C3%B3mo-registrarte-en-bimbau-4.0"
          rel="noopener"
          style={{ color: theme.colors.secondary }}
        >
          <Info3 style={{ textDecoration: "underline" }}>
            👉Descubre cómo hacerlo aquí👈
          </Info3>
        </a>
      </Info>
      <ul>
        <li>
          <Info3>
            Ingresa a
            <a
              href="http://bimbau.co/"
              style={{ color: theme.colors.secondary }}
            >
              bimbau.co
            </a>{" "}
            y dirígete a la <strong>Librería BIM</strong> que encuentras en la
            barra lateral derecha.
          </Info3>
        </li>
        <li>
          <Info3>
            Selecciona la <strong>Categoría </strong>de los productos productos
            que quieres consultar.&nbsp;
          </Info3>
        </li>
        <li>
          <Info3>
            Con los filtros ubicados a la izquierda podrás precisar tu búsqueda
            por las variaciones y características de los productos.
          </Info3>
        </li>
      </ul>
      <blockquote>
        <Info>
          <strong>📎TIPS:</strong> Ten presente que puedes ver la cantidad de
          productos que comprenden la categoría y bajar al final para verlos
          todos.
        </Info>
      </blockquote>
      <ul>
        <li>
          <Info3>
            En los detalles puedes ver información adicional y productos
            relacionados.&nbsp;
          </Info3>
        </li>
      </ul>
      <Info>
        <Info3>
          <img
            alt="Cómo navegar el catálogo de BIMBAU"
            class="hs-image-align-none"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/C%C3%B3mo%20navegar%20el%20cat%C3%A1logo%20de%20BIMBAU.gif"
            width={isMobile ? "100%" : "688px"}
          />
        </Info3>
      </Info>
      <Info2 style={{ textAlign: "center" }}>
        Y listo, navega por el catálogo de<strong>&nbsp;BIMBAU</strong>
        &nbsp;para consultar y comprar productos.
      </Info2>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="adjudicate-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/6O5_eIAnVY0?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
