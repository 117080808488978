import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Code = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        &nbsp;Es el código que se le asigna a cada producto que permite
        reconocerlo mediante líneas verticales con diversos grosores, este
        código generalmente es leído por un scanner y ofrece información del
        producto, no solo de precio y nombre sino de más características que
        facilita el inventario.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
