import React from "react";
import { useMediaQuery } from "react-responsive";

import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const AdjudicateTender = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <img
          alt="Licitaciones"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Licitaciones.jpg"
          width={isMobile ? "100%" : "688px"}
        />
        <br></br>
        <br></br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; <Info>Adjudica una licitación en 3 pasos&nbsp;</Info>
      </Title>
      <Info>
        Ten presente que para adjudicar una licitación debes estar registrado
        como <strong>Comprador&nbsp;</strong>y tener los permisos otorgados por
        parte del <strong>Administrador </strong>para realizar esta acción.
      </Info>
      <Info>
        Ingresa con usuario y contraseña a{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co/">
          BIMBAU
        </a>{" "}
        y dirígete a&nbsp;
        <strong>Administración</strong> desde tu perfil.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images603d21e60a2dae5b58fb47a9file-5dgwlqkhax.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info>
        En el módulo de <strong>Licitaciones</strong> que encuentras en la barra
        lateral izquierda, escoge la licitación y selecciona{" "}
        <strong>Editar.</strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5faee39746e0fb0017fcbcf2file-FRPGFgyJjs.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info>
        <br></br>Una vez terminado el plazo de recepción de la licitación ve a
        la pestaña de <strong>Cuadro Comparativo</strong> que encuentras en la
        parte superior.
      </Info>
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images601abdf81f25b9041bebb00afile-Zmh89j6UuB.png"
        width={isMobile ? "100%" : "688px"}
      />
      <br></br>
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa3133ecff47e0017d2ea5dfile-MrFFOqENxs-1.png"
        width="176px"
      />
      <br></br>
      <br></br>
      <Info>
        BIMBAU te recomienda la mejor propuesta para tu proyecto basado en las
        variables cualitativas y cuantitativas determinadas anteriormente en la
        licitación.&nbsp;
      </Info>
      <Info>
        Lee toda la información desplegando los detalles y luego selecciona{" "}
        <strong>Adjudicar licitación.</strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images603d4903b3ebfb109b5872f2file-DvneIg5xyT.png"
        width={isMobile ? "100%" : "688px"}
      />
      <br></br>
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images601abe171f25b9041bebb00cfile-WjZqBGmcCx.png"
        width="169px"
      />
      <br></br>
      <br></br>
      <Info>
        Las licitaciones pueden ser adjudicadas de manera{" "}
        <strong>parcial</strong> o <strong>total</strong>. Para hacerlo,
        <strong>&nbsp;selecciona</strong> uno o varios proveedores a los cuales
        se la adjudicarás.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images6024580ffb34b55df443f9c6file-6hyEuuQap0.png"
      />{" "}
      <br></br>
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images601abe37ac2f834ec5384df4file-guX9VnmScH.png"
        width="174px"
      />
      <br></br>
      <br></br>
      <Info>
        Si escogiste más de un proveedor, determina en cada uno el{" "}
        <strong>monto adjudicado </strong>basado en el total de la licitación y
        luego, define el <strong>anticipo </strong>y la{" "}
        <strong>forma de pago, </strong>una vez terminado esto,&nbsp;selecciona
        <strong> siguiente.&nbsp;</strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images602458386867724dfc6f1f8efile-WvVW4WkDVH.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info2>
        Ten presente que para especificar y dar claridad de la licitación puedes
        adjuntar<strong> documentos</strong> con la lista de insumos y
        servicios.&nbsp;<br></br>
        <br></br>
      </Info2>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images601abe61a4cefb30ae5c6eecfile-YqZ69lJfER.png"
        width="174"
      />
      <br></br>
      <br></br>
      <Info>
        Por último, BIMBAU te arrojará un mensaje estandarizado para enviar a
        aquellos proveedores que no fueron seleccionados. Puedes personalizar el
        mensaje y agregar en documentos la carta de agradecimiento de tu
        constructora.
      </Info>
      <Info>
        Una vez hecho, selecciona <strong>Adjudicar y enviar mensajes </strong>y
        listo.&nbsp;<br></br>
        <br></br>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images6024585f1f25b9041bebdc7afile-8xp6OEBgRP.png"
      />
      <br></br>
      <Title>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; De esa manera
        habrás adjudicado la licitación.
      </Title>
      <Info>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        ¡Mira este video y conoce el paso a paso para adjudicar una Licitación!
      </Info>
      <Info style={{ textAlign: "center" }}>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="adjudicate-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/XROZ59mKDp4?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
