import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const AdminReceivedOrders = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="Ordenes-de-compra-recibidas (1)"
        src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Ordenes-de-compra-recibidas%20(1).jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <Title style={{ textAlign: "center" }}>
        ¡Mira este video y conoce el paso a paso para administrar tus órdenes de
        compra!
      </Title>
      <br></br>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: " 100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="admin-received-orders"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/FXxIrTxys_4?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
