import styled from "styled-components";
import { theme } from "../../styles/theme";
import heroAzul from "../../assets/img/hero-azul-02.png";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 390px;
  background-image: url(${heroAzul});
  background-size: cover;
  background-position: center center;
  position: relative;
  color: #ffffff;
  padding: 0 3.75rem 3.75rem 3.75rem;

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 0 1.75rem 1.75rem 1.75rem;
    text-align: center;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 120px;

  @media (max-width: ${theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 310px;
  justify-content: flex-end;

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const WrapperCard = styled.div`
  display: flex;
  padding: 66px 115px 150px 115px;

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 30px;
    justify-content: center;
    align-items: center;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 68px 26px 52px 26px;
`;
