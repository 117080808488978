import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation } from "react-router-dom";
import { BreadCrumb } from "../components/Breadcrumb";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { ServicesButtons } from "../components/ServicesButtons";
import { theme } from "../styles/theme";
import "./App.css";

const App = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });
  const location = useLocation();
  const { pathname } = location;
  return (
    <section>
      {pathname === "/" ? (
        <main>
          <Outlet />
          <Footer />
        </main>
      ) : (
        <>
          <main>
            <Header />
            <div className="wrapper-content">
              <div
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <div style={{ width: "100%", padding: "1rem 0 2.325rem" }}>
                  <BreadCrumb />
                </div>
                <div className="wrapper-services-content">
                  <div style={{ width: isMobile ? "100%" : "30%" }}>
                    <ServicesButtons />
                  </div>
                  <div
                    style={{
                      width: isMobile ? "100%" : "70%",
                      marginTop: isMobile ? "30px" : "0px",
                    }}
                  >
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </main>
        </>
      )}
    </section>
  );
};

export default App;
