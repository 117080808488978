import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ResponseTender = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images6009ded32e764327f87c10a0file-mEJGEYbMsK.png"
      />
      <Title>
        <br></br>
        <br></br>Aprende a responder licitaciones en unos cuantos pasos.
      </Title>
      <Info>
        Para responder una licitación en BIMBAU debes estar registrado como
        VENDEDOR.
      </Info>
      <br></br>
      <Info>
        Ten presente que puedes recibir la invitación a participar en la
        licitación de dos maneras:
      </Info>
      <Title>
        <img
          alt=""
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa955e0cff47e00160b7f37file-OkjcVbA1ip.png"
          width={isMobile ? "100%" : "601px"}
        />
        <br></br>
        <br></br>¿Cómo responder una licitación?
      </Title>
      <ol>
        <li>
          <Info>
            Ingresa a <a href="http://bimbau.co">bimbau.co</a> y en el módulo de{" "}
            <strong>Licitaciones</strong> escoge la licitación en la que quieres
            participar seleccionando en{" "}
            <strong>
              ver licitación.&nbsp;<br></br>
              <br></br>
              <img
                alt=""
                src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa9578ccff47e00160b7f4afile-U09DC5HOj6.png"
                width={isMobile ? "100%" : "601px"}
              />
            </strong>
          </Info>
        </li>
        <li>
          <Info>
            Cuando hayas leído la información inicial de la licitación
            selecciona <strong>Estoy interesado</strong> para conocer con mayor
            detalle la solicitud enviada por la constructora. Esto te habilitará
            la pestaña de <strong>Presentar oferta.&nbsp; </strong>
            <em>
              Te recordamos que al presionar "Estoy interesado" no estás
              obligado a participar en la licitación.<br></br>
              <br></br>
            </em>
            <img
              alt=""
              src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa958594cedfd001610af11file-Gt6ZKwnsZC.png"
              width={isMobile ? "100%" : "601px"}
            />
          </Info>
        </li>
        <li>
          <Info>
            En la pestaña de <strong>Documentos&nbsp;</strong> que encuentras en
            la parte superior puedes consultar y completar datos e información
            importante que requieran en la licitación. Ten en cuenta que los
            documentos <strong>Para diligenciar</strong> son obligatorios
            responderlos.
          </Info>
        </li>
        <li>
          <Info>
            Ahora, dirígete a la pestaña de <strong>"Presentar oferta"</strong>{" "}
            y diligencia la <strong>Productos a cotizar</strong>&nbsp;que se
            solicitan en la licitación. Para hacerlo,{" "}
            <strong>descarga la plantilla</strong>
            <br></br>
            <br></br>
            <img
              alt="Productos a cotizar_v2"
              src="https://f.hubspotusercontent40.net/hubfs/6957948/Productos%20a%20cotizar_v2.jpg"
              width={isMobile ? "100%" : "601px"}
            />
          </Info>
        </li>
        <li>
          <Info>
            <strong>Responde</strong> la oferta en la plantilla del
            <strong> Excel</strong> de licitaciones.
          </Info>
        </li>
      </ol>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa95ae7cff47e0017d2fd53file-yxG17aU40j.png"
        width={isMobile ? "100%" : "601px"}
      />
      <Info2>
        <strong>
          Diligencia únicamente los campos que están identificados con el color
          azul y no agregues ni borres ninguna celda de la plantilla. Si no vas
          a ofertar un ítem solicitado simplemente deja el campo vacío.<br></br>
          <br></br>
        </strong>
      </Info2>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa95c4d4cedfd001610af36file-iljbSb8W5l.png"
        width={isMobile ? "100%" : "601px"}
      />
      <Info>
        <br></br>6. Cuando hayas terminado <strong>ca</strong>
        <strong>rga</strong> el archivo diligenciado en la lista de productos.
      </Info>
      <Info2>Completa los campos de la propuesta económica.</Info2>
      <Info>
        En cada licitación las constructoras pueden otorgar un porcentaje a
        determinadas condiciones de la licitación, como la propuesta económica,
        las condiciones comerciales, los tiempos de despacho, el transporte, la
        experiencia, y otras condiciones.&nbsp;&nbsp; Estos porcentajes
        determinarán el valor que le darán a cada una.
      </Info>
      <Info>
        En la propuesta económica, BIMBAU calcula el monto{" "}
        <strong>Total </strong>basado en la <strong>lista de productos</strong>{" "}
        cargados en la licitación, determina si a ese total aplican{" "}
        <strong>d</strong>
        <strong>escuentos</strong> e
        <strong>
          {" "}
          IVA.&nbsp;<br></br>
          <br></br>
        </strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa95f1246e0fb0017fca099file-yH25ZDshfb.png"
        width={isMobile ? "100%" : "601px"}
      />
      <Info>
        <br></br>Ahora, en <strong>Condiciones comerciales</strong> digita
        cuántos días regirán la <strong>forma de pago</strong> total de la
        oferta.&nbsp; Además, si lo requiere, escribe el{" "}
        <strong>anticipo</strong> que aplicará para tu propuesta. Por último,
        determina el tiempo de ejecución.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa95f20cff47e0017d2fd71file-ShyuTDaHDg.png"
        width={isMobile ? "100%" : "601px"}
      />
      <Info>
        <br></br>Para finalizar responde las <strong>Otras condiciones</strong>{" "}
        que haya determinado la constructora y si tienes información adicional
        agrégala en la licitación.&nbsp;
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa95f2a4cedfd00165ae6acfile-Z7YEuF61Sn.png"
        width={isMobile ? "100%" : "601px"}
      />
      <Info>
        <br></br>En el apartado de <strong>Preguntas</strong> puedes comunicarte
        con el encargado de la licitación y hacer las preguntas que necesites.
      </Info>
      <Info2>
        Selecciona enviar licitación y listo, ya habrás enviando tu
        propuesta.&nbsp;
      </Info2>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: " 100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="participate-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/jR-vNN9gBks?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
