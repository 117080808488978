import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const Companies = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="Constructoras"
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Constructoras.jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <Title>
        Descubre todas las constructoras que se encuentran en BIMBAU
      </Title>
      <Info>
        1. Ingresa a{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co">
          bimbau.co&nbsp;
        </a>
        y dirígete al
        <strong> Catálogo</strong> que encuentras en la barra central de
        arriba.&nbsp;
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fe269ecf24ccf588e3fdd6bfile-7ubqivfbMn.png"
      />
      <Info>
        <br></br>2. Dirígete a <strong>Empresa</strong> que encuentras en la
        parte superior izquierda.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fe26a6041fcb56e4047b552file-FVgHSexy5Y.png"
      />
      <Info>
        <br></br>3. Selecciona <strong>Constructores</strong> y desde ahí puedes
        consultar todas las constructoras que ya están en
        <strong>
          {" "}
          BIMBAU.&nbsp;<br></br>
        </strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fe26a7d6451e825e3b8d608file-HTfZNw0eK4.png"
      />
      <Info>
        Ten presente que también puedes escribir el nombre en el buscador para
        una búsqueda más detallada.&nbsp;
      </Info>
      <Title>Así puedes ver todas las constructoras que están en BIMBAU.</Title>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
