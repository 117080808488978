import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { Home } from "./Home";
import { Vendors } from "./Vendors";
import { Buyers } from "./Buyers";
import { Designers } from "./Designers";
import { Questions } from "./Questions";
import { Glossary } from "./Glossary";
import { Instructives } from "./Instructives";
import { Results } from "./Results";
import { CreateTender } from "./Buyers/create-tender";
import { DeleteCache } from "./Buyers/delete-cache";
import { BuyBimbau } from "./Buyers/buy-bimbau";
import { AdminGenerateOrders } from "./Buyers/admin-generate-orders";
import { DownloadReport } from "./Buyers/download-reports";
import { CreateApprovalFlow } from "./Buyers/create-approval-flows";
import { LibraryBimbau } from "./Buyers/library-bimbau";
import { CreateProject } from "./Buyers/create-project";
import { AdminReceivedOrders } from "./Vendors/admin-received-orders";
import { DownloadReportsVendor } from "./Vendors/download-reports";
import { UploadImageProducts } from "./Vendors/upload-image-products";
import { DownloadBim } from "./Designers/download-bim";
import { ProblemsPage } from "./Questions/problems-page";
import { PartLoadDocuments } from "./Questions/part-load-documents";
import { ResetPasswordGlossary } from "./Glossary/reset-password";
import { UseConditions } from "./Glossary/use-conditions";
import { RequestBimObject } from "./Instructives/request-bim-object";
import { InviteUser } from "./Instructives/invite";
import { RegisterForTender } from "./Instructives/register-for-tender";
import { AdminRequest } from "./Buyers/admin-request";
import { CreateAgreement } from "./Buyers/create-agreement";
import { CreateSolped } from "./Buyers/create-solped";
import { AdminSolped } from "./Buyers/admin-solped";
import { AdjudicateTender } from "./Buyers/adjudicate-tender";
import { AdjudicateQuotation } from "./Buyers/adjudicate-quotation";
import { CreateQuotation } from "./Buyers/create-quotation";
import { UpdateProductss } from "./Vendors/update-products";
import { CreateCoverage } from "./Vendors/create-coverage";
import { UploadProducts } from "./Vendors/upload-products";
import { TenderSended } from "./Vendors/tender-sended";
import { ParticipateTender } from "./Vendors/participate-tender";
import { ResponseTender } from "./Vendors/response-tender";
import { ResponseQuotation } from "./Vendors/response-quotation";
import { NoShowQuotation } from "./Vendors/no-show-quotations";
import { ChangePrices } from "./Questions/change-prices";
import { ResetPassword } from "./Questions/reset-password";
import { UpdateProducts } from "./Questions/update-products";
import { ApproveAgreement } from "./Questions/approve-agreement";
import { AccessRol } from "./Questions/access-rol";
import { AccessBimbau } from "./Questions/access-bimbau-info";
import { Bimbau } from "./Questions/bimbau";
import { AgreementProducts } from "./Questions/agreement-products-buy";
import { ItemsTender } from "./Questions/items-tender";
import { Agreement } from "./Questions/agreement";
import { Companies } from "./Questions/companies";
import { Plantillas } from "./Glossary/plantillas";
import { Quotation } from "./Glossary/quotation";
import { Bau } from "./Glossary/bau";
import { Conditions } from "./Glossary/conditions";
import { Rol } from "./Glossary/rol";
import { Buyer } from "./Glossary/buyer";
import { Zones } from "./Glossary/zone";
import { Sku } from "./Glossary/sku";
import { Keys } from "./Glossary/keys";
import { Approver } from "./Glossary/approver";
import { Url } from "./Glossary/url";
import { Bim } from "./Glossary/bim";
import { Admin } from "./Glossary/admin";
import { Code } from "./Glossary/code";
import { AgreementPrice } from "./Glossary/agreement-price";
import { PurchaseOrders } from "./Glossary/purchase-orders";
import { Omniclass } from "./Glossary/omniclass";
import { Cloud } from "./Glossary/cloud";
import { Ignore } from "./Glossary/ignore";
import { Register } from "./Instructives/register";
import { AdminUser } from "./Instructives/admin-user";
import { CreateAgreementVendor } from "./Vendors/create-agreement";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="comprador" element={<Buyers />} />
          <Route path="vendedor" element={<Vendors />} />
          <Route path="diseñador" element={<Designers />} />
          <Route path="preguntas-frecuentes" element={<Questions />} />
          <Route path="glosario" element={<Glossary />} />
          <Route path="instructivos-generales" element={<Instructives />} />
          <Route path="resultados" element={<Results />} />
          <Route path="como-crear-licitacion" element={<CreateTender />} />
          <Route
            path="cómo-borrar-las-cookies-y-el-caché-de-mi-navegador"
            element={<DeleteCache />}
          />
          <Route path="como-comprar-en-bimbau" element={<BuyBimbau />} />
          <Route
            path="como-administrar-ordenes-de-compra-generadas"
            element={<AdminGenerateOrders />}
          />
          <Route
            path="como-descargar-informes-comprador"
            element={<DownloadReport />}
          />
          <Route
            path="cómo-crear-los-flujos-de-aprobación"
            element={<CreateApprovalFlow />}
          />
          <Route
            path="cómo-navegar-el-catálogo-de-bimbau-4.0"
            element={<LibraryBimbau />}
          />
          <Route
            path="cómo-crear-un-proyecto-en-bimbau"
            element={<CreateProject />}
          />
          <Route path="cómo-administrar-pedidos" element={<AdminRequest />} />
          <Route
            path="cómo-crear-una-negociación-4.0"
            element={<CreateAgreement />}
          />
          <Route path="cómo-hacer-una-solped" element={<CreateSolped />} />
          <Route path="cómo-administrar-las-solped" element={<AdminSolped />} />
          <Route
            path="cómo-adjudicar-una-licitación"
            element={<AdjudicateTender />}
          />
          <Route
            path="cómo-adjudicar-cotización"
            element={<AdjudicateQuotation />}
          />
          <Route
            path="cómo-cotizar-insumos-y-servicios"
            element={<CreateQuotation />}
          />
          <Route
            path="como-actualizar-tus-productos"
            element={<UpdateProductss />}
          />
          <Route
            path="como-crear-una-negociacion-de-precio"
            element={<CreateAgreementVendor />}
          />
          <Route
            path="como-administrar-ordenes-de-compra-recibidas"
            element={<AdminReceivedOrders />}
          />
          <Route
            path="como-descargar-informes-vendedor"
            element={<DownloadReportsVendor />}
          />
          <Route
            path="como-crear-una-cobertura-en-bimbau"
            element={<CreateCoverage />}
          />
          <Route
            path="cómo-cargar-productos-en-bimbau-4.0"
            element={<UploadProducts />}
          />
          <Route
            path="cómo-agregar-las-imágenes-de-mis-productos-para-hacer-una-carga-masiva"
            element={<UploadImageProducts />}
          />
          <Route
            path="cómo-saber-si-mi-licitación-fue-enviada"
            element={<TenderSended />}
          />
          <Route
            path="como-puedo-participar-en-una-licitacion"
            element={<ParticipateTender />}
          />
          <Route
            path="cómo-responder-licitaciones"
            element={<ResponseTender />}
          />
          <Route
            path="cómo-responder-cotizaciones"
            element={<ResponseQuotation />}
          />
          <Route
            path="no-puedo-visualizar-la-cotización"
            element={<NoShowQuotation />}
          />
          <Route path="cómo-descargar-objetos-bim" element={<DownloadBim />} />
          <Route
            path="tengo-problemas-con-la-página-web"
            element={<ProblemsPage />}
          />
          <Route
            path="puedo-cargar-parcialmente-documentos-al-momento-de-participar-en-una-licitación"
            element={<PartLoadDocuments />}
          />
          <Route
            path="los-precios-de-mis-productos-cambian-constantemente"
            element={<ChangePrices />}
          />
          <Route
            path="cómo-restablecer-la-contraseña"
            element={<ResetPassword />}
          />
          <Route
            path="cómo-modifico-la-información-de-mis-productos"
            element={<UpdateProducts />}
          />
          <Route
            path="quién-aprueba-una-negociación-de-precio"
            element={<ApproveAgreement />}
          />
          <Route
            path="qué-accesos-tiene-un-rol-de-nuevo-usuario"
            element={<AccessRol />}
          />
          <Route
            path="quién-puede-acceder-a-la-información-de-bimbau"
            element={<AccessBimbau />}
          />
          <Route path="cómo-nace-bimbau" element={<Bimbau />} />
          <Route
            path="para-realizar-las-compras-de-un-proyecto-los-insumos-y-servicios-deben-estar-negociados"
            element={<AgreementProducts />}
          />
          <Route
            path="dónde-puedo-ver-los-ítems-a-ofertar-en-una-licitación"
            element={<ItemsTender />}
          />
          <Route
            path="las-negociaciones-de-precio-aplican-por-proyectos-o-por-cobertura"
            element={<Agreement />}
          />
          <Route
            path="descubre-las-constructoras-en-bimbau"
            element={<Companies />}
          />
          <Route
            path="cómo-reestablecer-la-contraseña"
            element={<ResetPasswordGlossary />}
          />
          <Route path="plantillas" element={<Plantillas />} />
          <Route path="cotización" element={<Quotation />} />
          <Route path="bau" element={<Bau />} />
          <Route path="condiciones-comerciales" element={<Conditions />} />
          <Route path="rol-o-roles" element={<Rol />} />
          <Route path="comprador-definición" element={<Buyer />} />
          <Route path="zona" element={<Zones />} />
          <Route path="sku" element={<Sku />} />
          <Route path="palabras-clave" element={<Keys />} />
          <Route path="aprobador" element={<Approver />} />
          <Route path="url" element={<Url />} />
          <Route path="bim" element={<Bim />} />
          <Route path="administración" element={<Admin />} />
          <Route path="código-de-barras" element={<Code />} />
          <Route path="negociaciones-de-precio" element={<AgreementPrice />} />
          <Route path="orden-de-compra" element={<PurchaseOrders />} />
          <Route
            path="omniclass-o-categoría-omniclass"
            element={<Omniclass />}
          />
          <Route path="nube" element={<Cloud />} />
          <Route path="palabras-a-ignorar" element={<Ignore />} />
          <Route path="condiciones-de-uso" element={<UseConditions />} />
          <Route
            path="cómo-solicitar-un-objeto-bim"
            element={<RequestBimObject />}
          />
          <Route path="como-registrarte-en-bimbau" element={<Register />} />
          <Route path="cómo-invitar-a-un-usuario" element={<InviteUser />} />
          <Route path="cómo-administrar-y" element={<AdminUser />} />
          <Route
            path="como-registrarse-en-bimbau-licitaciones"
            element={<RegisterForTender />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
