import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const TenderSended = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt=""
        class="private-image image-plugin-component hs-image-align-none"
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fb53abd4cedfd001610e129file-4fo69OF0vI.png"
      />
      <Title style={{ textAlign: "center" }}>
        ¿Cómo si mi licitación fue enviada?
      </Title>
      <Info>
        Existen dos maneras para confirmar que tu propuesta comercial fue
        enviada a la constructora.
      </Info>
      <Title>Desde BIMBAU</Title>
      <Info>
        &nbsp; Una vez enviada la propuesta puedes verificar su estado
        ingresando a la licitación.&nbsp;
      </Info>
      <Info>
        <img
          alt="Licitacion"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Licitacion.png"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Title>Desde el correo</Title>
      <Info>
        Llegará un correo desde BIMBAU validando que fue enviada tu propuesta
        comercial a la constructora.
      </Info>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
