import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ResetPassword = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        Para restablecer&nbsp;tu contraseña en BIMBAU sigue estos pasos.&nbsp;
      </Title>
      <Info>
        1. Entra a&nbsp;
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co/">
          bimbau.co
        </a>
        &nbsp;y dirígete a&nbsp;<strong>Ingresa.</strong>
      </Info>
      <Info>
        2. Selecciona&nbsp;<strong>¿Olvidaste la contraseña?</strong>&nbsp;y
        completa el campo el correo electrónico asociado a la cuenta.&nbsp;
      </Info>
      <Info>
        3. Selecciona el botón&nbsp;<strong>RECORDAR. Al correo&nbsp;</strong>
        electrónico llegará un mensaje con el enlace.
      </Info>
      <Info>
        4. Ve al enlace y reestablece la contraseña según las condiciones.&nbsp;
      </Info>
      <Info2>Y listo, ya tienes una nueva contraseña.</Info2>
      <Info>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        <strong>
          ¡Para más detalles acerca de cómo recuperar tu contraseña mira este
          video!
        </strong>
      </Info>
      <Info style={{ textAlign: "center" }}>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: " 100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="reset-password"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/m_7wOHPejRQ?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          </div>
        </div>
      </div>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
