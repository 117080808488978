import React from "react";
import { questions } from "../../utilities/questions";
import { Services } from "../../components/Services";

export const Glossary = () => {
  const glossary = questions.filter((ele) => ele.service === "glosario");

  return (
    <Services
      title={"Glosario"}
      message={
        "¿No conoces algún término de BIMBAU? Encuentra y consulta todas las palabras referentes a BIMBAU."
      }
      questions={glossary}
    />
  );
};
