import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Agreement = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Las <strong>negociaciones de precio</strong> podrán ser aplicadas por{" "}
        <strong>
          <em>proyecto</em>
        </strong>{" "}
        o por{" "}
        <strong>
          <em>cobertura </em>
        </strong>
        según las necesidades del comprador y del vendedor. Ten presente que si
        seleccionas una cobertura y no seleccionas un proyecto, la negociación
        de precio aplicará para todos lo proyectos de la constructora que se
        encuentren dentro de la cobertura; incluido proyectos vigentes, nuevos o
        recién creados además, de los proyectos que se crearán posteriormente.
      </Info>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
