import React from "react";
import ReactDOM from "react-dom";
import { AppRouter } from "./pages/App.router";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
