import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateTender = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <div>
      <QuestionTitle />
      <Title>
        <img
          alt="Licitaciones"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Licitaciones.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Title>
      <Title>Para hacer una licitación sigue estos 3 pasos</Title>
      <Info>
        Ten en cuenta que para crear una licitación debes estar registrado como
        Constructor en la plataforma y asociado a una empresa
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fac13ee4cedfd001610bd78file-QbNCOQXKqN.png"
        width={isMobile ? "100%" : "407px"}
      />
      <Info>
        <br></br>Ve a <strong>Administración</strong>&nbsp;que encuentras en la
        esquina superior derecha, una vez dentro selecciona el módulo de{" "}
        <strong>Licitaciones</strong> y dirígete a{" "}
        <strong>Crear licitación.</strong>
      </Info>
      <Title>
        <strong>Ahora comienza a crearla.</strong>
      </Title>
      <Info>
        Para empezar elige el tipo de licitación que harás: <strong>S</strong>
        <strong>uministro o servicio&nbsp;</strong>y, posterior a esto determina
        la <strong>cobertura</strong>, lo puedes hacer seleccionar&nbsp;el botón
        con el signo <strong>+</strong> filtrando los departamentos que cubren
        la licitación.
      </Info>
      <Info>
        Elige las categorías de los suministros o servicios que vas a licitar.
        Puedes elegir todas las que necesites&nbsp;y selecciona qué tipo de
        solicitud está haciendo: listado de precios, información o una
        propuesta.
      </Info>
      <Info>
        Ahora, determina el tipo de contrato que regirá la negociación, si será
        de suministro o servicio. Ten en cuenta que este campo debe concordar
        con el tipo de licitación.&nbsp;
      </Info>
      <Info>
        En el alcance puedes describir todos los detalles que consideres
        necesarios y asignar un presupuesto que puede estar o no visible.&nbsp;
      </Info>
      <Info>
        Luego, escoge si la participación será pública o privada y además
        selecciona si esta licitación aplica para negociación de precios.
      </Info>
      <Title>Avanzados</Title>
      <Info>
        En la sección de <strong>Avanzados</strong> que está identificado con
        una franja azul podrás completar información detallada de la licitación,
        cómo asignar un responsable, programar el día y la hora que quieres que
        sea visible la licitación,&nbsp; idioma, divisa, observaciones y
        ubicación.&nbsp;Luego, haz clic en guardar.<br></br>
        <br></br>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fac14354cedfd00165af502file-PYxUwdHVIU.png"
        width={isMobile ? "100%" : "350px"}
      />
      <Info>
        <br></br>Ahora completaremos toda la información técnica de la
        licitación, en el <strong>Cronograma</strong> fijarás una fecha de
        inicio y de fin para la recepción de documentos y de las ofertas.&nbsp;
      </Info>
      <Info>
        En Equipo asigna&nbsp; los usuarios de perfil financiero, técnico y
        comercial que estarán involucrados en la licitación. Deberás asignar al
        menos un usuario de perfil técnico, esto con el fin de que cada
        licitación&nbsp;
      </Info>
      <Info>Todas la licitacinones&nbsp;</Info>
      <Info>
        En la sección <strong>Documentos</strong> completaremos primero los
        puntajes de la licitación.
      </Info>
      <Info>(DEJAR ESTO EN BLANCO)</Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fac1451cff47e0017d30c0cfile-NmoPNhLvVm.png"
        width={isMobile ? "100%" : "319px"}
      />
      <br></br>
      <br></br>
      <Info>
        En el directorio selecciona las empresas que deseas que participen y
        filtra por los contactos comerciales.
      </Info>
      <Info>
        Verifica que todo en la licitación esté correcta y haz clic en guardar y
        publicar.
      </Info>
      <Title style={{ textAlign: "center" }}>
        Así ya tendrás lista la licitación.
      </Title>
      <Info>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        ¡Para más detalles acerca del módulo de Licitaciones mira este video!
      </Info>
      <div
        class="hs-embed-wrapper"
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">&nbsp;</div>
      </div>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          width: " 100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div>
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="create-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/fPXyNWHoaVg?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
