import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Bim = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Building Information Modeling (conocida en español como Modelado de
        Información para la Construcción) es una metodología innovadora que
        permite a través de una realidad virtual manejar un sistema de datos e
        información de todas las fases de un proyecto de construcción.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
