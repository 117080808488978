import React from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "./styles";
import { Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import { useDispatch } from "react-redux";
import { setService } from "../../store/common";

export const ServicesButtons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const buttons = [
    { name: "Comprador", link: "/comprador" },
    { name: "Vendedor", link: "/vendedor" },
    { name: "Diseñador", link: "/diseñador" },
    { name: "Preguntas frecuentes", link: "/preguntas-frecuentes" },
    { name: "Glosario", link: "/glosario" },
    { name: "Instructivos generales", link: "/instructivos-generales" },
  ];

  const handleService = (link, name) => {
    navigate(link);
    dispatch(setService(name));
  };

  return (
    <div>
      <Row>
        {buttons.map((ele, indx) => (
          <Col key={indx} md={24} sm={24} xs={24}>
            <Wrapper onClick={() => handleService(ele.link, ele.name)}>
              <Text
                color={theme.colors.secondary}
                line="1em"
                size={theme.fonts.size.sm}
                family="Roboto Slab"
              >
                {ele.name}
              </Text>
            </Wrapper>
          </Col>
        ))}
      </Row>
    </div>
  );
};
