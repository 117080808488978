import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const AgreementPrice = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Negociaciones de precio, conocido también como Contrato Marco o Acuerdo
        de precio es el ejercicio de definir o acordar un precio equitativo y
        que beneficie a las partes involucradas de algún producto o servicio por
        un tiempo estipulado.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
