import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import arrow from "../../assets/icons/arrow-right.svg";
import { useSelector } from "react-redux";

export const BreadCrumb = () => {
  const navigate = useNavigate();
  const { common } = useSelector((state) => state);
  const { serviceSelected } = common;

  const handleHome = () => {
    navigate("/");
  };

  return (
    <Flex gap="5px" height="min-content">
      <Text
        family="Roboto Slab"
        color={theme.colors.gray}
        size={theme.fonts.size.xs}
        style={{ cursor: "pointer" }}
        onClick={handleHome}
      >
        Centro de ayuda I BIMBAU
      </Text>
      <img alt="arrow" src={arrow} />
      <Text
        family="Roboto Slab"
        size={theme.fonts.size.xs}
        style={{ textTransform: "capitalize" }}
      >
        {serviceSelected}
      </Text>
    </Flex>
  );
};
