import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";

export const ProblemsPage = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>¿Has tenido problemas para navegar la plataforma?&nbsp;</Title>
      <Info>
        Si presentas inconvenientes para navegar la plataforma, te recomendamos
        que realices estos dos pasos para mejorarla experiencia.&nbsp;&nbsp;
      </Info>
      <Info>
        <strong>1.</strong> Si estás conectado desde la red interna empresarial,
        revisa las restricciones en la red (Proxy o Firewalle).&nbsp;
      </Info>
      <Info>
        <strong>2. </strong>Para navegar mejor usa los siguientes navegadores:{" "}
        <strong>Chrome, FIrefox Mozzilla y Opera.</strong>
      </Info>
      <Info>
        <strong>3. </strong>Revisa si tu conexión a internet estable.
      </Info>
      <Info>
        <strong>
          Si continuas teniendo problemas para navegar escríbenos o llámanos.
        </strong>
      </Info>
    </Flex>
  );
};
