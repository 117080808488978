import styled from "styled-components";
import { theme } from "../theme";

export const Flex = styled.div`
  display: flex;
  flex: ${({ flex }) => flex || null};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  gap: ${({ gap }) => gap || null};
  margin: ${({ m }) => m || null};
  padding: ${({ p }) => p || null};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  min-width: ${({ minwidth }) => minwidth || null};
  min-height: ${({ minheight }) => minheight || null};
  max-width: ${({ maxwidth }) => maxwidth || null};
  max-height: ${({ maxheight }) => maxheight || null};
  flex-direction: ${({ direction }) => direction || null};
`;

export const Text = styled.h1`
  font-family: ${({ family }) => family || "Montserrat"};
  font-size: ${({ size }) => size || theme.fonts.size.default};
  font-weight: ${({ weight }) => weight || theme.fonts.weight.regular};
  line-height: ${({ line }) => line || "2.75rem"};
  color: ${({ color }) => color || theme.colors.secondary};
  margin: 0;
`;

export const ServiceTitle = styled.h1`
  font-family: Montserrat;
  font-size: ${theme.fonts.size.h3};
  font-weight: 500;
  line-height: 2.75rem;
  color: ${theme.colors.secondary};
  margin: 0;
`;

export const ServiceMessage = styled.h6`
  font-family: Montserrat;
  font-size: ${theme.fonts.size.sm};
  font-weight: ${theme.fonts.weight.regular};
  line-height: 1.5rem;
  color: ${theme.colors.secondary};
  padding-bottom: 1.3125rem;
  margin-bottom: 1.75rem;
  margin-top: 0.375rem;
  border-bottom: 1px solid ${theme.colors.light3};
`;

export const ServiceQuestion = styled.h6`
  font-family: Montserrat;
  text-decoration: none;
  font-size: ${theme.fonts.size.sm};
  font-weight: ${theme.fonts.weight.regular};
  line-height: 1.5rem;
  color: ${theme.colors.gray};
  cursor: pointer;
  margin: 0;

  :hover {
    color: ${theme.colors.gray};
    text-decoration: underline;
  }
`;

export const Title = styled.h3`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.875rem;
  color: ${theme.colors.secondary};
`;

export const Info = styled.p`
  font-family: Roboto Slab;
  font-size: ${theme.fonts.size.default};
  line-height: 1.625rem;
  color: ${theme.colors.secondary};
`;

export const Info2 = styled.h4`
  font-family: Roboto Slab;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.625rem;
  color: ${theme.colors.secondary};
`;

export const Info3 = styled.span`
  font-family: Roboto Slab;
  font-size: ${theme.fonts.size.default};
  line-height: 1.625rem;
  color: ${theme.colors.secondary};
  text-decoration: underline;
`;
