import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 40px;
  width: 70%;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;

  :hover {
    background: rgba(0, 62, 82, 0.1);
  }
`;
