import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "common",
  initialState: {
    searchResults: [],
    serviceSelected: "",
    questionSelected: "",
  },
  reducers: {
    setSearchResult: (state, action) => {
      return {
        ...state,
        searchResults: action.payload,
      };
    },
    setService: (state, action) => {
      return {
        ...state,
        serviceSelected: action.payload,
      };
    },
    setQuestion: (state, action) => {
      return {
        ...state,
        questionSelected: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setSearchResult, setService, setQuestion } = slice.actions;
