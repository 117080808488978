import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const AdminSolped = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="Como-hacer-SOLPED"
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Como-hacer-SOLPED.jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <Title>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;Administra tus SOLPED
      </Title>
      <Info>
        &nbsp;Para administrar SOLPED debes estar registrado como Comprador en{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co/">
          BIMBAU
        </a>{" "}
        y tener los permisos otorgados.
      </Info>
      <Info>
        1.{" "}
        <a
          style={{ color: theme.colors.secondary }}
          href="https://app.bimbau.co/autenticacion"
        >
          Ingresa
        </a>{" "}
        a{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co/">
          bimbau.co
        </a>{" "}
        y accede con usuario y contraseña.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images603d11338502d1120e90c3b9file-REUB659cEG.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info>
        &nbsp;Desde tu perfil que encuentras en la esquina superior derecha, ve
        a&nbsp;&nbsp; <strong>Administración.</strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images602eb5a40a2dae5b58fb131afile-Limfw0zSNU.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info>
        <br></br>3. Una vez dentro de <strong>Administración</strong>, en la
        barra lateral izquierda dirígete a{" "}
        <strong>
          Solicitudes de pedido.<br></br>
        </strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images602eb830661b720174a6e9b5file-y1E7IkpZnm.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info>
        <br></br>En ese espacio encuentras todas las solicitudes de pedido.
        Puedes hacer una búsqueda más precisa seleccionando{" "}
        <strong>
          FILTRAR.<br></br>
        </strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images602a883b661b720174a6d2f0file-l1aatasYAv.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info2>
        Puedes filtrar por proyecto, almacén, centro de costos, solicitante,
        estado y fecha.
      </Info2>
      <Info>
        4. Para administrar una solicitud, selecciónala y en la parte superior
        derecha determina si vas&nbsp; <strong> A</strong>
        <strong>probar </strong>o <strong>rechazar</strong> la solicitud.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images602a95dcb3ebfb109b582693file-oKf1tMrpzN.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info2 style={{ textAlign: "center" }}>
        Ten presente que las SOLPED estarán ordenadas por fecha de entrega para
        priorizar su administración.
      </Info2>
      <Title style={{ textAlign: "center" }}>
        ¡Listo! Así puedes administrar tus SOLPED.
      </Title>
      <Info style={{ textAlign: "center" }}>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        ¡Mira este video y conoce el paso a paso para administrar tus SOLPED!
      </Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="admin-solped"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/bDcqYEPB8fM?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
