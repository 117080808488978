import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const Results = () => {
  const location = useLocation()
  const { search } = location
  const term = new URLSearchParams(search).get('term')
  const { common } = useSelector((state) => state)
  const { searchResults } = common
  const [arrayQuestions, setArrayQuestions] = useState([])

  useEffect(() => {
    if (searchResults?.length > 0) {
      setArrayQuestions(searchResults)
    }
  }, [searchResults])

  return (
    <div>
      <h1>{`Resultados para ${term}`}</h1>
      <hr style={{ height: "1px", width: "100%", marginBottom: "8px", marginTop: "5px" }}/>
      <Row style={{ width: "100%" }}>
        {arrayQuestions.map((ele, indx) => (
          <Col key={indx} md={24}>
            <div style={{ height: "30px" }}>
              <a href="/">{ele.label}</a>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}