import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";
import { theme } from "../../styles/theme";

export const Admin = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Es el módulo donde puedes acceder a todas las funcionalidades de{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co">
          BIMBAU
        </a>
        , desde ese espacio puedes realizar edición de perfil, participar y
        crear cotizaciones y licitaciones, asignar roles, establecer Zonas y
        más.&nbsp;
      </Info>
      <Info>
        <img
          alt=""
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images60009db5cfe30d219ccd7e15file-56ZYRCmr1q.png"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
