import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";
import { theme } from "../../styles/theme";

export const DownloadReport = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="como_descargar_informes"
        src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/como_descargar_informes.jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              href="http://bimbau.co/"
              rel="noopener"
              style={{ color: theme.colors.secondary }}
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>
            &nbsp;con<strong>&nbsp;Usuario</strong>&nbsp;y
            <strong>&nbsp;Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a
            <em>
              <strong> Informes </strong>
            </em>
            que encuentras en la barra superior.
          </Info>
        </li>
        <li>
          <Info>
            Selecciona el tipo de <strong>Informe</strong> que necesites
            descargar.
            <blockquote>
              &nbsp;📍Ten presente que puedes descargar informes de{" "}
              <strong>Órdenes de compra, Negociaciones</strong> y{" "}
              <strong>Productos.</strong>
            </blockquote>
          </Info>
        </li>
        <li>
          <Info>
            Establece un rango de periodicidad para descargar el informe.&nbsp;
            <blockquote>
              📍Puedes descargarlo por mes o personalizar las fechas.
            </blockquote>
          </Info>
        </li>
        <li style={{ textAlign: "left" }}>
          <Info>
            Para finalizar, haz clic en <strong>Descarga</strong> y
            listoAutomáticamente se descargará un archivo con el informe.&nbsp;
            <strong>⚠️NOTA&nbsp;</strong>
            <span class="hs-search-highlight hs-highlight-html">
              <strong>IMPORTANTE</strong>⚠️
            </span>
            &nbsp;Los informes de{" "}
            <strong>Negociaciones y Órdenes de compra</strong> pueden ser
            descargados por los <strong>Administradores de la cuenta. </strong>
            También pueden hacerlo, en el caso de{" "}
            <strong>Negociaciones </strong>
            aquellas personas encargadas o responsable de la Negociación, y en
            el caso de <strong>Órdenes de compra</strong>, el usuario que
            solicito los insumos.
          </Info>
        </li>
      </ul>
    </Flex>
  );
};
