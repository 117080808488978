import styled from "styled-components";
import { theme } from "../../styles/theme";
import heroAzul from "../../assets/img/hero-azul-02.png";

export const HeaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  height: 100px;
  width: 100%;
  background-image: url(${heroAzul});
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  z-index: 1;
  padding: 0 3.75rem 3.75rem 3.75rem;

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 0 1.75rem 2.75rem 1.75rem;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 120px;

  @media (max-width: ${theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: 120px;

  @media (max-width: ${theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;
