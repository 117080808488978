import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateQuotation = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="Cotizaciones"
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Cotizaciones.jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <Title>Cotiza tus insumos y servicios 2 pasos.</Title>
      <Info>
        Para hacer una cotización i
        <strong>ngresa con usuario y contraseña</strong> a
        <a href="http://bimbau.co"> bimbau.co</a>.&nbsp;Luego dirígete a la
        esquina superior derecha y en el perfil, ve a{" "}
        <strong>Administración</strong>.&nbsp;&nbsp;Selecciona{" "}
        <strong>Cotizaciones</strong> y haz clic en{" "}
        <strong>CREAR cotizaciones.&nbsp;</strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa3273c4cedfd00165ad461file-mxOfcXCun5.png"
        width={isMobile ? "100%" : "505px"}
      />
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa3257fcff47e00160b6c8afile-X1DpemNISo.png"
        width={isMobile ? "100%" : "442px"}
      />
      <br></br>
      <br></br>
      <Info>
        <strong>+</strong>&nbsp; Para empezar, especifica qué tipo de cotización
        será y escoge categoría del insumo o servicio que necesitas cotizar.
      </Info>
      <Info>
        <strong>+</strong>&nbsp; Selección la cobertura del requerimiento
        desplegando la lista de ciudades y municipios.&nbsp;
      </Info>
      <Info>
        <strong>+</strong> En el campo de descripción puedes escribe los
        detalles para el proveedor acerca del requerimiento.
      </Info>
      <Info>
        &nbsp;&nbsp;
        <em>
          Atención. La observación será vista solo por tu equipo de trabajo.
        </em>
      </Info>
      <Info2>
        Después de llenar todos los campos haz clic en el botón SIGUIENTE.
        <br></br>
        <br></br>
      </Info2>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa325894cedfd00165ad44cfile-5Ckyxvg9Kl.png"
        width={isMobile ? "100%" : "356px"}
      />
      <Info>
        <strong>
          <br></br>+{" "}
        </strong>
        En el directorio aparecerán proveedores relacionados con la categoría y
        cobertura geográfica que elegiste.&nbsp;Selecciona las empresas que
        deseas que participen y filtra por los contactos comerciales.&nbsp;
      </Info>
      <Info>
        <strong>+ </strong>Si la empresa que quieres invitar no se encuentra en
        la plataforma, podrás agregarla escribiendo el correo del
        proveedor.&nbsp;
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa328a646e0fb0017fc8e2bfile-SVvk2ZOWlN.png"
        width={isMobile ? "100%" : "428px"}
      />
      <Title>
        Luego invita a los proveedores y ya tendrás lista la cotización.
      </Title>
      <Info>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        ¡Mira este video y conoce el paso a paso para crear una Cotización!
      </Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="create-quotation"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/Olo0GlpZtyM?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
