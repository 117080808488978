import React from "react";
import { questions } from "../../utilities/questions";
import { Services } from "../../components/Services";

export const Instructives = () => {
  const instructives = questions.filter(
    (ele) => ele.service === "instructivos-generales"
  );

  return (
    <Services
      title={"Instructivos generales"}
      message={
        "¿Necesitas registrarte, actualizar documentos o actualizar roles? Descubre aquí todos los instructivos."
      }
      questions={instructives}
    />
  );
};
