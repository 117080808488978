import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ParticipateTender = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="Portada7-V"
        src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Portada7-V.jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <Title>Sigue estos pasos para participar en una licitación</Title>
      <Info>
        1. Ingresa con usuario y contraseña a&nbsp;
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co/">
          bimbau.co
        </a>
      </Info>
      <Info>
        2. Dirígete a&nbsp;
        <strong>Licitaciones</strong>&nbsp;que encuentras a la izquierda de la
        barra superior.&nbsp;Ahí vas a visualizar todas las licitaciones a las
        donde te han&nbsp; invitado a participar y aquellas que están
        públicas.&nbsp;&nbsp;
      </Info>
      <Info2>
        📍NOTA: También puedes ingresar directamente en{" "}
        <a
          style={{ color: theme.colors.secondary }}
          href="https://licitaciones.bimbau.co/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Info3>licitaciones.bimbau.co</Info3>
        </a>
      </Info2>
      <Info>
        <strong>
          <img
            alt="Licitaciones (2)"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/Licitaciones%20(2).jpg"
            width={isMobile ? "100%" : "688px"}
          />
        </strong>
      </Info>
      <Info>
        <em>
          <strong>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;R
          </strong>
          <strong>
            ecuerda que hay licitaciones públicas y privadas.&nbsp;
          </strong>
        </em>
      </Info>
      <Info>
        3. Ingresa a la licitación que quieres participar y selecciona&nbsp;
        <strong>Estoy interesado.</strong>
      </Info>
      <Info>4. Completa todos los datos de la licitación y listo.</Info>
      <Info2 style={{ textAlign: "center" }}>
        &nbsp; &nbsp; &nbsp; &nbsp;Así puedes participar en las licitaciones de
        BIMBAU&nbsp;
      </Info2>
      <Info>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        ¡Para más detalles acerca de administrar Usuarios mira este video!
      </Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: " 100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="participate-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/jR-vNN9gBks?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
