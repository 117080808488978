import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateApprovalFlow = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <img
          alt="Flujo-de-aprobación"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Flujo-de-aprobaci%C3%B3n.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Title>
      <Title>Qué es un flujo de aprobación</Title>
      <Info>
        Los flujos de aprobación son la estructura que las compañías establecen
        para la liberación de sus pedidos de compra. Estos te permiten
        verificar, modificar y liberar las solicitudes de compra de insumos y
        servicios para los proyectos basados en los montos de compra.
      </Info>
      <blockquote>
        <Info>
          <strong>📎TIPS: </strong>Los flujos pueden aplicar o no en un
          proyecto. Si no aplican, las solicitudes de compra se envían
          automáticamente al proveedor que despacha el pedido.
        </Info>
      </blockquote>
      <Title>Así creas los flujos de aprobación I Varios aprobadores</Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              href="http://bimbau.co/"
              rel="noopener"
              style={{ color: theme.colors.secondary }}
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            con<strong>&nbsp;Usuario</strong>&nbsp;y
            <strong>&nbsp;Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a<strong> Proyectos</strong> que encuentras en la parte superior.
          </Info>
        </li>
        <li>
          <Info>
            Selecciona <strong>Crear proyecto</strong> y completa la
            información.
          </Info>
          <blockquote>
            <Info>
              <strong>📎TIPS</strong>: Ten presente que para acceder a los
              flujos, debes completar la información del proyecto, o si ya
              creaste uno, puedes editarlo desde <strong>Acciones </strong>y
              realizar el flujo.
            </Info>
          </blockquote>
        </li>
      </ul>
      <Info>
        📍Para comenzar a configurar los montos de aprobación, ten en cuenta que
        estos determinarán qué persona de tu equipo aprueba la compra. Es decir,
        el aprobador que escojas será aquel que apruebe todos los pedidos y las
        compras dentro del rango de montos.
      </Info>
      <ul>
        <li>
          <Info>
            &nbsp;Una vez dentro de los Flujos de aprobación, selecciona la{" "}
            <strong>cantidad mínim</strong>a y la{" "}
            <strong>cantidad máxima</strong>
            &nbsp; del costo de las compras y pedidos de tu proyecto.
          </Info>
        </li>
        <li>
          <Info>
            Asigna al <strong>Aprobador</strong> seleccionando de la lista
            desplegable de colaboradores.
          </Info>
        </li>
        <li>
          <Info>
            Y finaliza, haciendo clic en el signo<strong> más (+)</strong> para
            agregar el monto del flujo.
          </Info>
        </li>
      </ul>
      <blockquote>
        <Info>
          <strong>⚠️IMPORTANTE⚠️</strong> Puedes repetir este paso cuantas veces
          creas necesario, recuerda que el monto mínimo del nuevo monto del
          flujo depende del máximo anterior.
        </Info>
      </blockquote>
      <Info>
        Ahora, si quieres determinar un aprobador para un montón específico en
        adelante, en la sección dos (2), selecciona al
        <strong> Aprobador </strong>en la lista desplegable de tus
        colaboradores.{" "}
        <em>
          Ten en cuenta que aparecerá siempre la{" "}
          <strong>cantidad máxima</strong> de la última serie de montos.&nbsp;
        </em>
      </Info>
      <Info>
        Para finalizar, si tienes algún <strong>Interventor</strong>, en la
        sección tres (3) puedes digitar su nombre y seleccionarlo. Estos
        interventores deben estar previamente registrados en tu cuenta para que
        puedas escogerlo.
      </Info>
      <Info style={{ textAlign: "center" }}>
        <a
          href="http://ayuda.bimbau.co/c%C3%B3mo-invitar-a-un-usuario"
          rel="noopener"
          style={{ color: theme.colors.secondary }}
        >
          <span style={{ textDecoration: "underline" }}>
            👉Ve aquí cómo invitar usuarios.👈
          </span>
        </a>
      </Info>
      <ul>
        <li>
          Selecciona<strong> Invitar usuarios</strong> y listo.&nbsp;
          <Info2 style={{ textAlign: "center" }}>
            ¡Ya tendrás tus flujos de aprobación creados!
          </Info2>
          <Info2 style={{ textAlign: "center" }}>&nbsp;</Info2>
          <Info2 style={{ textAlign: "center" }}>&nbsp;</Info2>
          <Info2 style={{ textAlign: "center" }}>
            ¡Para más detalles mira este video!
          </Info2>
        </li>
      </ul>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="create-approval-flow"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/JlGn3TbnnNQ?start=2&amp;feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
