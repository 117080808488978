export const questions = [
  {
    service: "comprador",
    label: "¿Cómo crear una Licitación?",
    link: "/como-crear-licitacion",
  },
  {
    service: "comprador",
    label: "¿Cómo borrar el Caché y el Service Worker de mi navegador?",
    link: "/cómo-borrar-las-cookies-y-el-caché-de-mi-navegador",
  },
  {
    service: "comprador",
    label: "¿Cómo comprar en BIMBAU?",
    link: "/como-comprar-en-bimbau",
  },
  {
    service: "comprador",
    label: "¿Cómo administrar órdenes de compra generadas?",
    link: "/como-administrar-ordenes-de-compra-generadas",
  },
  {
    service: "comprador",
    label: "¿Cómo descargar informes?",
    link: "/como-descargar-informes-comprador",
  },
  {
    service: "comprador",
    label: "¿Cómo crear los Flujos de aprobación?",
    link: "/cómo-crear-los-flujos-de-aprobación",
  },
  {
    service: "comprador",
    label: "¿Cómo navegar el catálogo de BIMBAU?",
    link: "/cómo-navegar-el-catálogo-de-bimbau-4.0",
  },
  {
    service: "comprador",
    label: "¿Cómo crear un proyecto en BIMBAU?",
    link: "/cómo-crear-un-proyecto-en-bimbau",
  },
  {
    service: "comprador",
    label: "¿Cómo administrar Pedidos para tus proyectos?",
    link: "/cómo-administrar-pedidos",
  },
  {
    service: "comprador",
    label: "¿Cómo crear una Negociación?",
    link: "/cómo-crear-una-negociación-4.0",
  },
  {
    service: "comprador",
    label: "¿Cómo hacer una SOLPED?",
    link: "/cómo-hacer-una-solped",
  },
  {
    service: "comprador",
    label: "¿Cómo administrar las SOLPED?",
    link: "/cómo-administrar-las-solped",
  },
  {
    service: "comprador",
    label: "¿Cómo adjudicar una Licitación?",
    link: "/cómo-adjudicar-una-licitación",
  },
  {
    service: "comprador",
    label: "¿Cómo adjudicar cotización?",
    link: "/cómo-adjudicar-cotización",
  },
  {
    service: "comprador",
    label: "¿Cómo crear una Cotización?",
    link: "/cómo-cotizar-insumos-y-servicios",
  },
  {
    service: "vendedor",
    label: "¿Cómo actualizar tus productos?",
    link: "/como-actualizar-tus-productos",
  },
  {
    service: "vendedor",
    label: "¿Cómo crear una Negociación?",
    link: "/como-crear-una-negociacion-de-precio",
  },
  {
    service: "vendedor",
    label: "¿Cómo administrar órdenes de compra recibidas?",
    link: "/como-administrar-ordenes-de-compra-recibidas",
  },
  {
    service: "vendedor",
    label: "¿Cómo descargar informes?",
    link: "/como-descargar-informes-vendedor",
  },
  {
    service: "vendedor",
    label: "¿Cómo crear una Cobertura en BIMBAU?",
    link: "/como-crear-una-cobertura-en-bimbau",
  },
  {
    service: "vendedor",
    label: "¿Cómo cargar productos en el catálogo BIMBAU?",
    link: "/cómo-cargar-productos-en-bimbau-4.0",
  },
  {
    service: "vendedor",
    label:
      "¿Cómo agregar las imágenes de mis productos para hacer una carga masiva?",
    link: "/cómo-agregar-las-imágenes-de-mis-productos-para-hacer-una-carga-masiva",
  },
  {
    service: "vendedor",
    label: "¿Cómo saber si mi licitación fue enviada?",
    link: "/cómo-saber-si-mi-licitación-fue-enviada",
  },
  {
    service: "vendedor",
    label: "¿Cómo participar en una licitación?",
    link: "/como-puedo-participar-en-una-licitacion",
  },
  {
    service: "vendedor",
    label: "¿Cómo responder licitaciones?*",
    link: "/cómo-responder-licitaciones",
  },
  {
    service: "vendedor",
    label: "¿Cómo responder cotizaciones?",
    link: "/cómo-responder-cotizaciones",
  },
  {
    service: "vendedor",
    label: "No puedo visualizar la cotización",
    link: "/no-puedo-visualizar-la-cotización",
  },
  {
    service: "diseñador",
    label: "¿Cómo descargar objetos BIM?",
    link: "/cómo-descargar-objetos-bim",
  },
  {
    service: "preguntas-frecuentes",
    label: "Tengo problemas con la página web",
    link: "/tengo-problemas-con-la-página-web",
  },
  {
    service: "preguntas-frecuentes",
    label:
      "¿Puedo cargar parcialmente documentos al momento de participar en una licitación?",
    link: "/puedo-cargar-parcialmente-documentos-al-momento-de-participar-en-una-licitación",
  },
  {
    service: "preguntas-frecuentes",
    label: "Los precios de mis productos cambian constantemente*",
    link: "/los-precios-de-mis-productos-cambian-constantemente",
  },
  {
    service: "preguntas-frecuentes",
    label: "¿Cómo restablecer la contraseña?",
    link: "/cómo-restablecer-la-contraseña",
  },
  {
    service: "preguntas-frecuentes",
    label: "¿Cómo modifico la información de mis productos?",
    link: "/cómo-modifico-la-información-de-mis-productos",
  },
  {
    service: "preguntas-frecuentes",
    label: "¿Quién aprueba una negociación de precio?",
    link: "/quién-aprueba-una-negociación-de-precio",
  },
  {
    service: "preguntas-frecuentes",
    label: "¿Qué accesos tiene un rol de Nuevo usuario?",
    link: "/qué-accesos-tiene-un-rol-de-nuevo-usuario",
  },
  {
    service: "preguntas-frecuentes",
    label: "¿Quién puede acceder a la información de BIMBAU?",
    link: "/quién-puede-acceder-a-la-información-de-bimbau",
  },
  {
    service: "preguntas-frecuentes",
    label: "¿Cómo nace BIMBAU?",
    link: "/cómo-nace-bimbau",
  },
  {
    service: "preguntas-frecuentes",
    label:
      "Para realizar las compras de un proyecto, ¿Los insumos y servicios deben estar negociados?",
    link: "/para-realizar-las-compras-de-un-proyecto-los-insumos-y-servicios-deben-estar-negociados",
  },
  {
    service: "preguntas-frecuentes",
    label: "¿Dónde puedo ver los ítems a ofertar en una licitación?",
    link: "/dónde-puedo-ver-los-ítems-a-ofertar-en-una-licitación",
  },
  {
    service: "preguntas-frecuentes",
    label: "Las negociaciones de precio aplican por proyectos o por cobertura",
    link: "/las-negociaciones-de-precio-aplican-por-proyectos-o-por-cobertura",
  },
  {
    service: "preguntas-frecuentes",
    label: "Descubre las constructoras en BIMBAU",
    link: "/descubre-las-constructoras-en-bimbau",
  },
  {
    service: "glosario",
    label: "¿Cómo reestablecer la contraseña?",
    link: "/cómo-reestablecer-la-contraseña",
  },
  { service: "glosario", label: "Plantillas", link: "/plantillas" },
  { service: "glosario", label: "Cotización", link: "/cotización" },
  { service: "glosario", label: "BAU", link: "/bau" },
  {
    service: "glosario",
    label: "Condiciones Comerciales",
    link: "/condiciones-comerciales",
  },
  { service: "glosario", label: "Rol o roles", link: "/rol-o-roles" },
  { service: "glosario", label: "Comprador*", link: "/comprador-definición" },
  { service: "glosario", label: "Zona", link: "/zona" },
  { service: "glosario", label: "SKU", link: "/sku" },
  { service: "glosario", label: "Palabras Clave", link: "/palabras-clave" },
  { service: "glosario", label: "Aprobador*", link: "/aprobador" },
  { service: "glosario", label: "URL", link: "/url" },
  { service: "glosario", label: "BIM", link: "/bim" },
  { service: "glosario", label: "Administración", link: "/administración" },
  { service: "glosario", label: "Código de Barras", link: "/código-de-barras" },
  {
    service: "glosario",
    label: "Negociaciones de Precio",
    link: "/negociaciones-de-precio",
  },
  { service: "glosario", label: "Orden de Compra", link: "/orden-de-compra" },
  {
    service: "glosario",
    label: "Omniclass (o Categoría Omniclass)",
    link: "/omniclass-o-categoría-omniclass",
  },
  { service: "glosario", label: "Nube", link: "/nube" },
  {
    service: "glosario",
    label: "Palabras a ignorar",
    link: "/palabras-a-ignorar",
  },
  {
    service: "glosario",
    label: "Condiciones de Uso",
    link: "/condiciones-de-uso",
  },
  {
    service: "instructivos-generales",
    label: "¿Cómo solicitar un objeto BIM?",
    link: "/cómo-solicitar-un-objeto-bim",
  },
  {
    service: "instructivos-generales",
    label: "¿Cómo registrarte en BIMBAU?",
    link: "/como-registrarte-en-bimbau",
  },
  {
    service: "instructivos-generales",
    label: "¿Cómo invitar a un usuario?",
    link: "/cómo-invitar-a-un-usuario",
  },
  {
    service: "instructivos-generales",
    label: "¿Cómo administrar Usuarios?",
    link: "/cómo-administrar-y",
  },
  {
    service: "instructivos-generales",
    label: "¿Cómo registrarse en BIMBAU para las licitaciones?",
    link: "/como-registrarse-en-bimbau-licitaciones",
  },
];
