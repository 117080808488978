import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import common from './common'

const reducer = combineReducers({
  common,
})
const store = configureStore({
  reducer
})
export default store
