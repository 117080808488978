import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const Register = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title style={{ textAlign: "center" }}>
        <img
          alt="Portada11-V"
          class="hs-image-align-none"
          src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Portada11-V.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Title>
      <Title style={{ textAlign: "center" }}>
        Regístrate
        <strong>
          {" "}
          en BIMBAU con unos simples pasos<br></br>
          <br></br>
        </strong>
      </Title>
      <Info>
        <strong>BIMBAU te permite registrarte fácilmente:</strong>
      </Info>
      <ul>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Ingresa a{" "}
            <strong>
              <em>bimbau.co</em>
            </strong>{" "}
            y dirígete a <strong>Regístrate</strong> que encuentras en la
            esquina superior derecha.
          </Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Completa todos los campos con tu información personal: nombre,
            apellido, correo, contraseña y celular. ⚠️
            <strong>
              <em>
                Ten presente que tu contraseña debe tener mínimo 8 caracteres,
                ser{" "}
              </em>
            </strong>
          </Info3>
          <strong>
            <em>
              alfanumérica
              <Info3 style={{ textDecoration: "none" }}>
                {" "}
                y tener al menos un{" "}
              </Info3>
              carácter
              <Info3 style={{ textDecoration: "none" }}> especial.</Info3>
            </em>
          </strong>
          <br></br>
          <Info3 style={{ textDecoration: "none" }}></Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Acepta los{" "}
            <strong>
              <a
                style={{ color: theme.colors.secondary }}
                href="https://bimbaustatic.s3.amazonaws.com/legal/TERMINOS+Y+CONDICIONES+BIMBAU+v1.pdf"
                rel="noopener"
              >
                Términos y condiciones.
              </a>
            </strong>
          </Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Selecciona la casilla<strong> No soy un robot.</strong>
          </Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Haz clic en el botón <strong>Regístrate</strong> y ¡Listo!
          </Info3>
        </li>
      </ul>
      <Info>
        <Info3 style={{ textDecoration: "none" }}>
          Una vez te hayas registrado en BIMBAU,{" "}
          <em>
            <strong>selecciona el perfil principal</strong>
          </em>{" "}
          que quieres tener en la plataforma. Puedes escoger entre{" "}
          <strong>Comprador, Vendedor y Diseñador.</strong>{" "}
        </Info3>
        <Info3 style={{ textDecoration: "none" }}></Info3>
      </Info>
      <blockquote>
        <Title style={{ textAlign: "center" }}>
          <Info3 style={{ textDecoration: "none" }}>
            <strong>📍NOTA:</strong> En BIMBAU tienes la posibilidad de tener
            uno o más perfiles para que así puedes planear más estratégicamente
            tus procesos.&nbsp;
          </Info3>
        </Title>
      </blockquote>
      <Title>Registro o asignación&nbsp;de empresa</Title>
      <Info>
        En BIMBAU, para realizar todos tus procesos debes tener una empresa o
        estar asignada a una. Luego del registro personal, ingresa en{" "}
        <strong>Asignar empresa</strong> el{" "}
        <strong>número de identificación</strong> para asociarte, si tu empresa
        no se encuentra creada previamente,{" "}
        <strong>puedes crearla siguiendo estos pasos:</strong>
      </Info>
      <ul>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Ingresa el<strong> Nombre </strong>o <strong>Razón social</strong> y
            el <strong>número de la empresa.</strong>
          </Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Escribe o selecciona la<strong> Dirección de la empresa</strong>
            <Info3> </Info3>y su <strong>Complemento</strong> si lo crees
            necesario.
          </Info3>
        </li>
      </ul>
      <blockquote>
        <Info>
          <strong>📎TIPS: </strong>
          <em>Ten en cuenta que en el </em>
          <strong>
            <Info3>Complemento</Info3>{" "}
          </strong>
          <em>
            puedes especificar algún punto de referencia, número de piso o
            bloque.
          </em>
        </Info>
      </blockquote>
      <ul>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Escoge la categorías -si serás vendedor- de tus productos.
          </Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Por último, el ingresa el{" "}
            <strong>Nombre del Representante Legal </strong>y&nbsp; su{" "}
            <strong>Correo electrónico.</strong>
            <br></br>
            <br></br>
            <strong>
              Antes de continuar valida todos los datos, haz clic en confirmo
              datos y Listo.
            </strong>
          </Info3>
          <br></br>
          <Info2 style={{ textAlign: "center" }}>
            Ya completaste tu registro
          </Info2>
        </li>
      </ul>
      <blockquote>
        <Info style={{ textAlign: "left" }}>
          📍Te invitamos a navegar en BIMBAU y empezar a mejorar tus{" "}
          <Info3 style={{ textAlign: "center" }}>
            procesos de abastecimiento y ventas.
          </Info3>
        </Info>
      </blockquote>
      <Info style={{ textAlign: "center" }}>
        <strong>
          <em>
            <img
              alt="Cómo registrarse en bimbau"
              class="hs-image-align-none"
              src="https://f.hubspotusercontent40.net/hubfs/6957948/C%C3%B3mo%20registrarse%20en%20bimbau.gif"
              width={isMobile ? "100%" : "688px"}
            />
          </em>
        </strong>
      </Info>
      <Info2 style={{ textAlign: "center" }}>
        <strong>
          ¡Para más detalles acerca de cómo registrarte en BIMBAU mira este
          video!
        </strong>
      </Info2>
      <Info>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="register"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/BxCgy3V3y14?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>

      <Info>&nbsp;</Info>
      <Info>&nbsp;</Info>
      <Info>&nbsp;</Info>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
