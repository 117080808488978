import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Ignore = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Las palabras a ignorar son ese grupo de palabras con las cuáles no
        quieres que tus productos o servicios sean asociados, estas palabras,
        por consiguiente serán excluidas por el buscador de BIMBAU y el producto
        no será filtrado en base a estos términos.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
