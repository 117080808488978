import React from "react";
import { questions } from "../../utilities/questions";
import { Services } from "../../components/Services";

export const Designers = () => {
  const designersQuestions = questions.filter(
    (ele) => ele.service === "diseñador"
  );

  return (
    <Services
      title={"Diseñador"}
      message={
        "Encuentra y lee todo los instructivos que necesitas como diseñador."
      }
      questions={designersQuestions}
    />
  );
};
