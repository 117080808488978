import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const AdminUser = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <img
          alt="Como-administrar-usuarios"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Como-administrar-usuarios.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Title>
      <Title>Gestión y administración de usuarios.</Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>
            &nbsp;y ingresa con<strong>&nbsp;Usuario&nbsp;</strong>y
            <strong>&nbsp;Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a<em> </em>
            <strong>Usuarios </strong>que encuentras en la parte superior.
          </Info>
        </li>
      </ul>
      <blockquote>
        <Info>
          <strong>TIPS: </strong>
          <em>
            En la sección de <Info3>Usuario</Info3>s encuentras todos los
            colaboradores de tu compañía que están en BIMBAU, con su información
            detallada y Rol.&nbsp;
          </em>
          &nbsp;
        </Info>
      </blockquote>
      <Title>Asignar Roles de Usuario.</Title>
      <Info>Para editar un usuario asignar un rol sique estos pasos.</Info>
      <ul>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Desde <strong>Usuarios</strong>, dirígete a Acciones,&nbsp;
            selecciona el ícono de <strong>Editar.</strong>
          </Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            En<strong> Rol </strong>escoge los permisos que tendrá el usuario.
          </Info3>
        </li>
        <li>
          <Info3 style={{ textDecoration: "none" }}>
            Selecciona <strong>Guardar</strong> y listo.
          </Info3>
          <br></br>
          <br></br>
          <img
            alt="como administrar usuarios"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20administrar%20usuarios.gif"
            width={isMobile ? "100%" : "688px"}
          />
          <br></br>
          <br></br>
          <Info3 style={{ textDecoration: "none" }}>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <strong>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; ¡Ya está asignado el rol!
            </strong>
          </Info3>
        </li>
      </ul>
      <Title>Aprobación de solicitudes de usuarios.</Title>
      <ul>
        <li>
          <Info>
            Desde <strong>Usuarios</strong>, ve a la pestaña de{" "}
            <strong>Solicitudes pendientes</strong> que encuentras en la barra
            superior.&nbsp;
          </Info>
        </li>
        <li>
          <Info>
            Dirígete a <strong>Acciones</strong> y selecciona el ícono de{" "}
            <strong>Editar.</strong>
          </Info>
        </li>
        <li>
          <Info>
            En <Info3>Rol</Info3> escoge los permisos que tendrá el usuario.
          </Info>
        </li>
        <li>
          <Info>
            Selecciona <strong>Guardar </strong>y listo.<br></br>
            <br></br>&nbsp; &nbsp;
            <strong>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp;¡Ya aceptaste un usuario a tu compañía!
            </strong>
            <br></br>
            <br></br>
          </Info>
        </li>
      </ul>
      <Info>
        <img
          alt="como aprobar solicitudes usuario"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20aprobar%20solicitudes%20usuario.gif"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Title style={{ textAlign: "center" }}>
        ¡Para más detalles mira este video!
      </Title>
      <Info>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="admin-user"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/JyWI8jmRjyo?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
