import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2 } from "../../styles/common";
import { theme } from "../../styles/theme";

export const UploadImageProducts = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="Agregar-imágenes-a-la-carga-masiva-de-productos"
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Agregar-im%C3%A1genes-a-la-carga-masiva-de-productos.jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <Info>
        Conoce el paso a paso para agregar las imágenes de tus productos a la
        plantilla de carga en bimbau.co. Con este paso podrás tener tus imágenes
        cargadas en internet o en la nube y generar así la URL, esto permitirá
        la visualización de las imágenes de tu productos en el catálogo.&nbsp;
      </Info>
      <Info>
        Ten presente que para cargar productos debes estar{" "}
        <a
          style={{ color: theme.colors.secondary }}
          href="http://bimbau.co"
          rel="noopener"
        >
          registrado en BIMBAU.
        </a>
      </Info>
      <Info2>
        <strong>Sube tus fotos a un sitio web</strong>
      </Info2>
      <Info>
        Antes de comenzar convertir las imágenes en URL, tenlas en una carpeta
        de tu computador&nbsp; nombradas con la identificación del producto.
      </Info>
      <Info>Empecemos.</Info>
      <ol>
        <li>
          <Info>
            Ingresa a{" "}
            <a
              style={{ color: theme.colors.secondary }}
              href="https://es.imgbb.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Imgbb
            </a>{" "}
            y haz clic<strong>&nbsp;Comienza a subir.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Selecciona desde la carpeta de tu computador<strong>&nbsp;</strong>
            la
            <strong> imagen</strong> del producto.
          </Info>
        </li>
        <li>
          <Info>
            En el desplegable debajo de la imagen, escoge
            <strong>
              {" "}
              <em>"no eliminar automáticamente".</em>
            </strong>
            <br></br>
            <br></br>
            <strong>
              &nbsp; ⚠️NOTA IMPORTANTE:{" "}
              <em>
                La selección de esta opción es clave para que la imagen de tu
                producto no caduque y se elimine del catálogo
              </em>
            </strong>
          </Info>
        </li>
        <li>
          Por último, selecciona SUBIR y listo. Copia la URL generada y pégala
          en la celda de "Imagen principal" de la plantilla.<br></br>
          <br></br>
        </li>
      </ol>
      <Info>
        <strong>
          <em>
            Ten en cuenta, que puedes agregar más imágenes a tu producto
            repitiendo este proceso e insertando la URL en el campo de "imágenes
            complementarias" de la plantilla.
          </em>
        </strong>
      </Info>
      <Info>
        Recuerda que también plataformas externas como&nbsp;
        <a
          style={{ color: theme.colors.secondary }}
          href="https://subefotos.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Subefotos
        </a>
        ,&nbsp;
        <a
          style={{ color: theme.colors.secondary }}
          href="https://es.imgbb.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Imgbb
        </a>
        &nbsp;y&nbsp;
        <a
          style={{ color: theme.colors.secondary }}
          href="https://postimages.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Postimages
        </a>
        &nbsp;te ofrecen este servicio.
      </Info>
      <Info2>
        <strong>Si vas a usar las fotos de tu sitio web</strong>
      </Info2>
      <Info>
        Deberás hacer clic derecho sobre la foto y elegir la opción "Copiar
        dirección de la imagen". Si deseas verificar si es correcta puedes
        pegarla en una nueva pestaña del navegador y
        <strong>
          &nbsp;revisar que el enlace termine en .jpg, jpeg, .png, .bmp o
          .webp.&nbsp;
        </strong>
      </Info>
      <Info2>
        <strong>Si ya tienes tus fotos en un servidor externo&nbsp;</strong>
      </Info2>
      <Info>
        Puedes copiarlas directamente desde ahí, pero debes asegurarte de que el
        enlace sea público. Es decir, que tenga los permisos adecuados para que
        cualquiera pueda visualizarlo y que termine con el formato adecuado.
      </Info>
      <Info>
        Ten presente que debes ser el titular de los derechos de autor sobre las
        fotos que uses o tener autorización de su titular para poder usarlas,
        en&nbsp;
        <a
          style={{ color: theme.colors.secondary }}
          href="https://www.mercadolibre.com.mx/ayuda/Sacar-buenas-fotos-productos_805"
          target="_blank"
          rel="noopener noreferrer"
        >
          cumplimiento con nuestras políticas.&nbsp;
        </a>
      </Info>
    </Flex>
  );
};
