import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateAgreementVendor = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        <strong>
          <img
            alt="Portada1-V"
            src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Portada1-V.jpg"
            width={isMobile ? "100%" : "688px"}
          />
          Las negociaciones
        </strong>{" "}
        son el acuerdo entre las partes involucradas en una relación comercial
        para establecer un precio equitativo de algún producto o servicio por un
        tiempo estipulado que, beneficie a ambos. Antes de crear una{" "}
        <strong>Negociación</strong> debes crear una
        <strong> Cobertura de la negociación, </strong>para hacerlo, sigue estos
        pasos.
      </Info>
      <Title>👍 Crea una cobertura de la Negociación</Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            e inicia sesión con <strong>Usuario </strong>y
            <strong> Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Negociación</strong> que encuentras en la parte
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Una vez dentro, dirígete a{" "}
            <strong>Coberturas de la negociación</strong> que encuentras en la
            barra superior.
          </Info>
        </li>
        <li>
          <Info>
            Haz clic en <strong>Crear cobertura.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Selecciona el país de la cobertura y haz clic en Crear cobertura.
            <br></br>
            <br></br>
            <strong>📎TIPS: </strong>Ten presente que puedes seleccionar todos
            los estados o provincias del país, si quieres segmentar la
            cobertura, una vez creada edita desde Acciones y escoge las ciudades
            y provincias especificas de la cobertura.
            <strong>&nbsp; ¡Ya creaste tu cobertura!</strong>
          </Info>
        </li>
      </ul>
      <Info>
        <strong>
          <img
            alt="como crear una negociacion-1"
            src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20crear%20una%20negociacion-1.gif"
            width={isMobile ? "100%" : "688px"}
          />
        </strong>
      </Info>
      <Title>Creemos una negociación en BIMBAU</Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            y accede con <strong>Usuario y Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Negociación </strong>que encuentras en la parte
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Haz clic en&nbsp; <strong>Crear negociación</strong>.<br></br>
          </Info>
          <Title>
            <img
              alt="como crear una negociacion-2"
              src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20crear%20una%20negociacion-2.gif"
              width={isMobile ? "100%" : "688px"}
            />
          </Title>
          <Title>
            <strong>Información general</strong>
            <strong>¡Comencemos!</strong>
          </Title>
        </li>
        <li>
          <Info>
            <strong> </strong>Escoge primero si los productos que estarán dentro
            de la <strong>Negociación</strong> son propios o pertenecen a una
            empresa o proveedor invitado.
          </Info>
        </li>
        <li>
          <Info>
            Escribe el <strong>Nombre de la empresa </strong>y selecciónala para
            realizar la negociación, además agrega&nbsp; a un Responsable que se
            encargará de administrar y aprobar la negociación.
          </Info>
        </li>
      </ul>
      <Info>
        Determina, luego si la negociación será visible para las empresas
        implicadas o para todo el publico.<br></br>
        <br></br>Al terminar, selecciona<strong> </strong>
        <strong>continuar</strong>
        <strong> </strong>para ir a la siguiente parte y seguir con la
        información de la negociación.<br></br>
      </Info>
      <Title>
        <strong>Información de la negociación</strong>
      </Title>
      <ul>
        <li>
          <Info>
            Determina el rango de tiempo donde primará la Negociación; digitando
            la fecha de inicio y fecha de fin.{" "}
            <em>
              <strong>
                Ten en cuenta que&nbsp;podrás modificar estas fechas cuando lo
                desees.&nbsp;
              </strong>
            </em>
            &nbsp;
          </Info>
        </li>
        <li>
          <Info>
            Selecciona la Moneda para esta negociación y la Cobertura donde se
            llevará a cabo.
          </Info>
        </li>
        <li>
          <Info>
            Escribe las<em> </em>Condiciones de pago<em> </em>y escoge el
            Proyecto o los Proyectos de la negociación.&nbsp;
          </Info>
        </li>
        <li>
          <Info>
            Por último, escribe las Condiciones comerciales.&nbsp;<br></br>
          </Info>
        </li>
      </ul>
      <Title>
        <strong>Productos de la negociación</strong>
      </Title>
      <ul>
        <li>
          <Info>
            Descarga la plantilla de productos y modifica el precio al nuevo
            precio de la negociación.
          </Info>
        </li>
        <li>
          <Info>
            Al terminar, carga la plantilla de productos y haz clic en{" "}
            <strong>Crear negociación.&nbsp;</strong>
          </Info>
        </li>
      </ul>
      <Info2>
        <strong>¡Listo! Tu Negociación de precio ya estará creada.</strong>
      </Info2>
      <Title>
        <strong>👍 Edita las negociaciones</strong>
      </Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            e inicia sesión con <strong>Usuario </strong>y
            <strong> Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Negociación</strong> que encuentras en la parte
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Escoge<strong>&nbsp;</strong>la<strong> Negociación </strong>que
            necesitas modificar y luego selecciona<strong> editar.</strong>
          </Info>
        </li>
      </ul>
      <Info2>
        📍 Ten en cuenta que solo{" "}
        <em>
          <strong>p</strong>
          <strong>uedes editar</strong>
        </em>{" "}
        las negociaciones en estado
        <strong>
          {" "}
          Pendiente o Rechazada y únicamente&nbsp;el usuario que crea la
          negociación puede editarla.&nbsp;&nbsp;
        </strong>
      </Info2>
      <ul>
        <li>
          <Info>
            <strong>Edita la información</strong> y luego guarda los
            cambios.&nbsp;
          </Info>
        </li>
      </ul>
      <blockquote>
        <Info>
          <strong>⚠️IMPORTANTE⚠️</strong> Todas las personas involucradas en la
          negociación recibirán una notificación al correo con los cambios que
          realizaste.
        </Info>
      </blockquote>
      <Info>
        <img
          alt="Editar Negociacion GIF-1"
          src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Editar%20Negociacion%20GIF-1.gif"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Title>
        <strong>👍Duplica las negociaciones</strong>
      </Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>{" "}
            e inicia sesión con <strong>Usuario </strong>y
            <strong> Contraseña.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Ve a <strong>Negociación</strong> que encuentras en la parte
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Escoge<strong>&nbsp;</strong>la<strong> Negociación </strong>que
            necesitas duplicar y haz clic en <strong>Acciones.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Selecciona <strong>Duplicar negociación</strong> y listo.<br></br>
            <br></br>
            <img
              alt="Duplicar Negociacion GIF-1"
              src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Duplicar%20Negociacion%20GIF-1.gif"
              width={isMobile ? "100%" : "655px"}
            />
          </Info>
        </li>
      </ul>
      <Info>
        <strong>⚠️IMPORTANTE⚠️ </strong>Si estás duplicando una negociación
        activa para actualizar la vigencia o los precios, recuerda inactivar la
        anterior.
      </Info>
    </Flex>
  );
};
