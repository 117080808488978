import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateSolped = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        <img
          alt="Como-hacer-SOLPED"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Como-hacer-SOLPED.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Title>Haz SOLPED desde BIMBAU</Title>
      <Info>
        Cuando algún requerimiento que necesitas para el proyecto no se
        encuentre dentro del catálogo de BIMBAU.
      </Info>
      <Info>
        Ten presente que para realizar una SOLPED debes estar registrado en
        BIMBAU como <strong>Comprador.</strong>
      </Info>
      <ol>
        <li>
          <Info>
            <strong>Ingresa</strong>&nbsp;a{" "}
            <a
              href="http://bimbau.co"
              style={{ color: theme.colors.secondary }}
            >
              bimbau.co
            </a>{" "}
            con usuario y contraseña. Una vez dentro, en la barra superior
            selecciona el proyecto que requiere hacer la solicitud de pedido.
            <img
              alt=""
              src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images602ee5d0b3ebfb109b583dd2file-Yg4m1Juq5l.png"
              width={isMobile ? "100%" : "688px"}
            />
          </Info>
        </li>
        <li>
          <Info>
            <strong>Dirígete</strong> al módulo de <strong>Pedidos </strong>que
            encuentras en la parte inferior derecha.&nbsp;
            <img
              alt=""
              src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fc7fe8deb7cc612aa35444ffile-R8DWHCytl2.png"
              width={isMobile ? "100%" : "688px"}
            />
          </Info>
        </li>
        <li>
          <Info>
            <strong>Llena</strong> los requerimientos del pedido escribiendo
            cada uno de los materiales o productos que vas a solicitar con su
            cantidad. Ten presente que puedes añadir{" "}
            <strong>observaciones</strong> y <strong>documentos</strong> para
            hacer más específica tu solicitud.
            <img
              alt=""
              src="https://f.hubspotusercontent40.net/hubfs/6957948/image-png.png"
              width={isMobile ? "100%" : "688px"}
            />
          </Info>
        </li>
      </ol>
      <Info>
        &nbsp; &nbsp;4. <strong>Selecciona</strong> el almacén, y el centro de
        costos.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images6015b597a4cefb30ae5c5a97file-AsaMCzxFk4.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Info>
        5. <strong>Escoge</strong> una fecha requerida del pedido y seleccionar
        solicitar.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images6015b5e76867724dfc6edd93file-f6c6DzYG77.png"
        width={isMobile ? "100%" : "688px"}
      />
      <Title style={{ textAlign: "center" }}>
        y... Listo, ya tendrás creada tu SOLPED.
      </Title>
      <Info style={{ textAlign: "center" }}>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        ¡Mira este video y conoce el paso a paso para administrar tu SOLPED!
      </Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="create-solped"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/bDcqYEPB8fM?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
