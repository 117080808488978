import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const UseConditions = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Son todas las condiciones que el proveedor recomienda para un
        rendimiento óptimo de un productos, bajo estas recomendaciones podrá dar
        garantías. Por ejemplo recomendaciones de temperatura, superficie,
        electricidad, etc.&nbsp;
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
