import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Keys = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Son palabras que ayudan a encontrar mejor tu producto en la búsqueda del
        catálogo. Estas palabras pueden ser sinónimos u otras formas de llamar
        los productos o servicios.&nbsp;
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
