export const theme = {
  colors: {
    white: "#FFFFFF",
    primary: "#0e3749",
    secondary: "#33475b",
    gray: "#003e52",
    gray2: "#516F90",
    gray3: "#2D3E50",
    light: "#7c98b6",
    light2: "#f5f8fa",
    light3: "#DFE3EB",
  },
  fonts: {
    size: {
      h1: "3rem",
      h2: "2.5rem",
      h3: "2rem",
      h4: "1.5rem",
      h5: "1.25rem",
      default: "1rem",
      sm: "0.875rem",
      xs: "0.75rem",
      xxs: "0.625rem",
      xxxs: "0.5rem",
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  },
  breakpoints: {
    xs: "320px",
    sm: "480px",
    md: "820px",
    lg: "992px",
    xl: "1200px",
  },
  zIndex: {
    base: 1,
    menu: 2,
    overlay: 3,
    modal: 4,
    toast: 5,
  },
};
