import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const AccessRol = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Las personas con un rol de Nuevo usuario o Empleado&nbsp;tienen accesos
        limitados a las funcionalidades de BIMBAU. Con este rol puede acceder
        al&nbsp;<strong>Catálogo</strong>&nbsp;y a la&nbsp;
        <strong>Librería BIM.</strong>
      </Info>
    </Flex>
  );
};
