import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Cloud = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        La nube es un espacio en la red que permite almacenar y acceder a datos
        y programas a través del internet, en lugar de usar un disco en el
        computador.
      </Info>
      <Info>
        “Es un término que se utiliza para describir una red mundial de
        servidores, cada uno con una función única. La nube no es una entidad
        física, sino una red enorme de servidores remotos de todo el mundo que
        están conectados para funcionar como un único ecosistema.
      </Info>
      <Info>
        Estos servidores están diseñados para almacenar y administrar datos,
        ejecutar aplicaciones o entregar contenido o servicios, como streaming
        de vídeos, correo web, software de ofimática o medios sociales. En lugar
        de acceder a archivos y datos desde un equipo personal o local, accede a
        ellos en línea desde cualquier dispositivo conectado a Internet, es
        decir, la información está disponible dondequiera que vaya y siempre que
        la necesite.” - Microsoft-
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
