import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Omniclass = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Es un sistema de organización y&nbsp; clasificación que te permite
        catalogar, nombrar y analizar todos los materiales que son utilizados en
        un proyecto de construcción.&nbsp; Éste sistema de clasificación está
        basado en una clasificación por códigos ordenados en diferentes tablas
        según su función, forma, uso etc, y su objetivo principal es la
        combinación de múltiples sistemas de clasificación existentes en uno
        sólo.
      </Info>
      <Info>
        Es el sistema de clasificación BIM de todos los productos y servicios en
        BIMBAU, que permite organizar todos los elementos disponibles en los
        modelos de información constructiva para lograr un lenguaje global y sea
        entendible para todos.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
