/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import search from "../../assets/icons/search.svg";
import { AutoComplete, Col, Input, Row } from "antd";
import { questions } from "../../utilities/questions";
import { servicesButtons } from "../../utilities/servicesButtons";
import { useDispatch } from "react-redux";
import { setQuestion, setSearchResult, setService } from "../../store/common";
import {
  Card,
  HeaderWrapper,
  InputWrapper,
  LogoWrapper,
  WrapperCard,
} from "./styles";
import { Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";

export const Home = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [arrayQuestions, setArrayQuestions] = useState(
    questions.map((ele) => ({
      value: ele.label,
      link: ele.link,
      service: ele.service,
    }))
  );
  const [searchBy, setSearchBy] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleSearch = (value) => {
    setSearchBy(value);
    let filteredQuestions = [];
    if (value) {
      filteredQuestions = arrayQuestions.filter((ele) =>
        ele.value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      );
      setFilterOptions(filteredQuestions);
      dispatch(setSearchResult(filteredQuestions));
      setForceUpdate(!forceUpdate);
    } else {
      setFilterOptions([]);
    }
  };

  const handleSearchInput = () => {
    if (searchBy) {
      const filteredQuestions = arrayQuestions.filter((ele) =>
        ele.value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            searchBy
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      );
      setArrayQuestions(filteredQuestions);
      dispatch(setSearchResult(filteredQuestions));
      navigate(`/resultados?term=${searchBy}`);
    } else {
      setArrayQuestions(arrayQuestions);
    }
  };

  const handleService = (link, name) => {
    navigate(link);
    dispatch(setService(name));
  };

  const handleSelect = (value) => {
    const url = arrayQuestions.find((ele) => ele.value === value)?.link;
    const service = arrayQuestions.find((ele) => ele.value === value)?.service;
    navigate(url);
    dispatch(setService(service));
    dispatch(setQuestion(value));
  };

  return (
    <Flex direction="column" style={{ backgroundColor: theme.colors.light2 }}>
      <HeaderWrapper>
        <LogoWrapper>
          <img
            width="233px"
            height="40px"
            alt="logo"
            src="https://6957948.fs1.hubspotusercontent-na1.net/hub/6957948/hubfs/azul%20oscuro-01.png?height=120&name=azul%20oscuro-01.png"
          />
        </LogoWrapper>
        <InputWrapper>
          <Text
            size={theme.fonts.size.h3}
            color={theme.colors.primary}
            weight={theme.fonts.weight.semibold}
            style={{ margin: "0 0 1rem" }}
          >
            ¿Cómo podemos ayudarte?
          </Text>
          <AutoComplete
            value={searchBy}
            onChange={handleSearch}
            options={searchBy ? filterOptions : []}
            onSelect={handleSelect}
            style={{ width: isMobile ? "100%" : "50%" }}
          >
            <Input
              placeholder="Buscar respuestas"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchInput();
                }
              }}
              prefix={
                <img
                  alt="search"
                  src={search}
                  width="14.2px"
                  height="14.2px"
                  style={{ marginRight: "8px" }}
                />
              }
              style={{
                height: "50px",
                fontFamily: "Montserrat",
                fontSize: theme.fonts.size.default,
                color: "#7c98b6",
                padding: "0.75em",
              }}
            />
          </AutoComplete>
        </InputWrapper>
      </HeaderWrapper>
      <WrapperCard>
        <Row gutter={[40, 40]} style={{ width: "100%" }}>
          {servicesButtons.map((ele, indx) => (
            <Col key={indx} md={8}>
              <Card onClick={() => handleService(ele.link, ele.label)}>
                <img
                  alt="icons-services"
                  src={ele.img}
                  width="74px"
                  height="74px"
                  style={{ margin: "0 auto 1.5rem auto" }}
                />
                <Text
                  size="1.375rem"
                  line="1.875rem"
                  weight={theme.fonts.weight.semibold}
                  color={theme.colors.gray3}
                  style={{ margin: "0 0 1.25rem 0" }}
                >
                  {ele.label}
                </Text>
                <Text line="1.625rem" color={theme.colors.gray2}>
                  {ele.message}
                </Text>
              </Card>
            </Col>
          ))}
        </Row>
      </WrapperCard>
    </Flex>
  );
};

export default Home;
