import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const PartLoadDocuments = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        En BIMBAU puedes ir cargando la documentación de una licitación que
        estés participando de manera parcial y quedará terminada y enviada
        cuando hayas completado la información.&nbsp;
      </Info>
    </Flex>
  );
};
