import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const RegisterForTender = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <img
        alt="Registro"
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Registro.jpg"
        width={isMobile ? "100%" : "688px"}
      />
      <Title>
        Registrarse en BIMBAU es muy fácil<br></br>
      </Title>
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fbeaba54cedfd001611090ffile-o8jRQyfux7-1.png"
        width={isMobile ? "100%" : "398px"}
      />
      <br></br>
      <Info>
        Ingresa a bimbau.co y dirígete a Regístrate que encuentras en la esquina
        superior derecha.
      </Info>
      <Info>
        Completa todos los campos con información personal: Correo, tipo de
        documentos, nombres, celular, etc.&nbsp;
      </Info>
      <Info>
        Una vez completado lo campos personales, al correo y al teléfono te
        llegará un código de verificación, escríbelo para verificar tu cuenta y
        crea una contraseña.&nbsp;
      </Info>
      <Info>
        Ahora selecciona el perfil que tendrás en BIMBAU: diseñador, comprador o
        vendedor.&nbsp;
      </Info>
      <Info>1. Si seleccionas diseñador ya completo tu registro.</Info>
      <Info>
        2. Si selecciona comprador o vendedor y su empresa está creada en BIMBAU
        digita el nit y asóciate a ella.&nbsp;
      </Info>
      <Info>3. Sino, haz el registro de tu empresa.</Info>
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fbeab974cedfd001611090efile-lYT7fWTZa3-1.png"
        width={isMobile ? "100%" : "437px"}
      />
      <br></br>
      <Info>Registra tu empresa en 2 pasos.</Info>
      <Info>
        1. Información de la empresa: Completa el nombre o razón social de tu
        empresa.&nbsp;
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fbeac0e4cedfd00165b3ffbfile-QHDnn80Anc-1.png"
      />
      <Info>
        <br></br>2. Detalles de la empresa: Llena los campos de tipo de
        proveedor, tipo de empresa, cobertura y dirección y página web.&nbsp;
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fbeac2446e0fb0017fcfaaefile-6BYHyeztYo-1.png"
      />
      <Info>
        <br></br>Ten presente que si seleccionaste tipo de proveedor de
        suministro escoge las categorías que enmarcan tus productos y si
        seleccionaste tipo de proveedor de servicios escoge el Código CIIU.
      </Info>
      <Info>Por último, confirma toda la información y listo.&nbsp;</Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fbeac50cff47e0017d35788file-usQjkt0ydB-1.png"
      />
      <Info2 style={{ textAlign: "center" }}>&nbsp;</Info2>
      <Info2 style={{ textAlign: "center" }}>
        Ya habrás completado tu registro.
      </Info2>
      <Info>&nbsp;</Info>
      <Info style={{ textAlign: "center" }}>
        ¡Para más detalles acerca del registro mira este video!
      </Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              Title="register-for-tender"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/MPMQBmqDDkA?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </Flex>
  );
};
