import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const DeleteCache = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <img
          alt="miniatura-cookies-y-caché"
          style={{ width: "688px" }}
          src="https://f.hubspotusercontent40.net/hubfs/6957948/miniatura-cookies-y-cach%C3%A9.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Title>
      <Title>Aprende a borrar las cookies y el caché de bimbau.co.</Title>
      <Title>📍Si tu navegador es Chrome</Title>
      <div>
        <div data-md="83">
          <ol>
            <li>
              <Info>
                Ingresa a <strong>app.bimbau.co</strong>
              </Info>
            </li>
            <li>
              <Info>
                Haz clic derecho en la pantalla y dirígete a
                <strong> Inspeccionar.&nbsp;</strong>
              </Info>
              <strong>
                <img
                  alt=""
                  src="https://f.hubspotusercontent40.net/hubfs/6957948/Eliminarcookies1y2-gif.gif"
                  height="364.79px"
                  width={isMobile ? "100%" : "648px"}
                />
              </strong>
            </li>
            <li>
              <Info>
                Se abrirá un panel,<strong>&nbsp; amplíalo </strong>y en la
                barra superior selecciona la pestaña
                <strong> Aplicación.</strong>
              </Info>
            </li>
            <li>
              <Info>
                En el menú de <strong>Aplicaciones,</strong> que encuentras en
                la barra lateral izquierda ve a
                <strong> "Service Workers".</strong>
              </Info>
            </li>
          </ol>
          <img
            alt=""
            src="https://f.hubspotusercontent40.net/hubfs/6957948/Eliminarcookies4-gif-1.gif"
            height="383.38px"
            width={isMobile ? "100%" : "681px"}
          />
          <br></br>
          <Info>
            5. A la derecha, dirígete a{" "}
            <strong>
              Unregister, luego cierra el panel y recargar la página.
            </strong>
          </Info>
          <Info>
            <strong>
              <img
                alt=""
                src="https://f.hubspotusercontent40.net/hubfs/6957948/eliminarcookies5-gif-1.gif"
                height="383.38px"
                width={isMobile ? "100%" : "681px"}
              />
            </strong>
          </Info>
        </div>
      </div>
      <div>
        <div>
          <div
            data-hveid="CAkQAA"
            data-ved="2ahUKEwjC5cjX8fP1AhXjRjABHW2TAocQFSgAegQICRAA"
          >
            <Title style={{ textAlign: "center" }}>
              &nbsp;Y listo, así borras tus Cookies y caché.
            </Title>
            <Info>&nbsp;</Info>
            <Title style={{ textAlign: "center" }}>
              ¡Para más detalles mira este video!
            </Title>
          </div>
        </div>
      </div>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="delete-cache"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/U0wtYQg16Vw?feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            />
          </div>
        </div>
      </div>
    </Flex>
  );
};
