import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Sku = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        SKU: O referencia de almacén es un código único y alfanumérico que se le
        asigna a un producto para identificarlo. Funciona&nbsp; en nuestra
        plataforma como el identificador del producto. También es el mismo
        código interno que dentro de tu organización define cada uno de los
        productos para su inventario.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
