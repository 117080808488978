import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const InviteUser = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <img
          alt="como-invitar-a-un-usuario"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/como-invitar-a-un-usuario.jpg"
          width={isMobile ? "100%" : "688px"}
        />
        Invitar a un usuario
      </Title>
      <ul>
        <li>
          <Info>
            Ingresa a
            <a
              style={{ color: theme.colors.secondary }}
              href="http://bimbau.co/"
              rel="noopener"
            >
              <em>
                <strong>&nbsp;bimbau.co</strong>
              </em>
            </a>
            &nbsp;y ingresa con&nbsp;Usuario&nbsp;y&nbsp;Contraseña.
          </Info>
        </li>
        <li>
          <Info>
            Ve a&nbsp;<strong>Usuarios&nbsp;</strong>que encuentras en la parte
            superior.
          </Info>
        </li>
        <li>
          <Info>
            Desde Usuarios, dirígete a <strong>Invitar usuarios.</strong>
          </Info>
        </li>
        <li>
          <Info>
            Escribe el{" "}
            <strong>
              <Info3>e-mail</Info3>{" "}
            </strong>
            en el campo de <strong>Correo electrónico.</strong>&nbsp;
          </Info>
        </li>
        <li>
          <Info>
            Escoge el <strong>Rol</strong> que tendrá el usuario dentro de tu
            cuenta de la lista desplegable.
          </Info>
        </li>
        <li>
          <Info>
            Selecciona <strong>Invitar usuarios</strong> y listo.<br></br>
          </Info>
        </li>
      </ul>
      <p>&nbsp;</p>
    </Flex>
  );
};
