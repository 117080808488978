/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
import { questions } from "../../utilities/questions";
import searchIcon from "../../assets/icons/search.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setQuestion, setSearchResult, setService } from "../../store/common";
import { HeaderWrapper, InputWrapper, LogoWrapper } from "./styles";
import { Flex } from "../../styles/common";
import { theme } from "../../styles/theme";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = location;
  const term = new URLSearchParams(search).get("term");
  const [arrayQuestions, setArrayQuestions] = useState(
    questions.map((ele) => ({
      value: ele.label,
      link: ele.link,
      service: ele.service,
    }))
  );
  const [searchBy, setSearchBy] = useState(term || "");
  const [filterOptions, setFilterOptions] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleSearch = (value) => {
    setSearchBy(value);
    let filteredQuestions = [];
    if (value) {
      filteredQuestions = arrayQuestions.filter((ele) =>
        ele.value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      );
      setFilterOptions(filteredQuestions);
      dispatch(setSearchResult(filteredQuestions));
      setForceUpdate(!forceUpdate);
    } else {
      setFilterOptions([]);
    }
  };

  const handleSearchInput = () => {
    if (searchBy) {
      const filteredQuestions = arrayQuestions.filter((ele) =>
        ele.value
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            searchBy
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      );
      setArrayQuestions(filteredQuestions);
      dispatch(setSearchResult(filteredQuestions));
      navigate(`/resultados?term=${searchBy}`);
    } else {
      setArrayQuestions(arrayQuestions);
    }
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleSelect = (value) => {
    const url = arrayQuestions.find((ele) => ele.value === value)?.link;
    const service = arrayQuestions.find((ele) => ele.value === value)?.service;
    navigate(url);
    dispatch(setService(service));
    dispatch(setQuestion(value));
  };

  return (
    <Flex>
      <HeaderWrapper>
        <LogoWrapper>
          <img
            alt="logo"
            src="https://6957948.fs1.hubspotusercontent-na1.net/hub/6957948/hubfs/azul%20oscuro-01.png?height=120&name=azul%20oscuro-01.png"
            width="233px"
            height="40px"
            style={{ cursor: "pointer" }}
            onClick={handleHome}
          />
        </LogoWrapper>
        <InputWrapper>
          <AutoComplete
            value={searchBy}
            onChange={handleSearch}
            options={searchBy ? filterOptions : []}
            onSelect={handleSelect}
            style={{ width: "100%" }}
          >
            <Input
              placeholder="Buscar respuestas"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchInput();
                }
              }}
              prefix={
                <img
                  alt="search"
                  src={searchIcon}
                  width="14.2px"
                  height="14.2px"
                  style={{ marginRight: "8px" }}
                />
              }
              style={{
                width: "85%",
                height: "50px",
                fontFamily: "Montserrat",
                fontSize: theme.fonts.size.default,
                color: "#7c98b6",
                padding: "0.75em",
              }}
            />
          </AutoComplete>
        </InputWrapper>
      </HeaderWrapper>
    </Flex>
  );
};
