import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const AccessBimbau = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        La información almacenada de los procesos en BIMBAU puede ser&nbsp;vista
        solo por los administradores de cuenta de cada una de las empresas
        registradas y aquellos a quién el&nbsp;<strong>Administrador</strong>
        &nbsp;le otorgue accesos.&nbsp;
      </Info>
      <Info>
        Además, la información cuenta con unos parámetros de seguridad que
        puedes leer a continuación:
      </Info>
      <ul>
        <li>
          <Info>
            La información está almacenada de manera segura en la nube,
            protegida por una capa de seguridad basada en VPN y una capa de
            Seguridad basada en roles y credenciales SSH de acceso.
          </Info>
        </li>
        <li>
          <Info>
            El envío de información desde las aplicaciones a los servidores,
            está encriptada usando un protocolo de TLS/SSL que asegura la
            integridad de la información End-to-End
          </Info>
        </li>
        <li>
          <Info>
            Contamos con una capa de Seguridad de acceso brindada por un
            servicio WAF que provee el servicio número uno CDN del mundo
            (Cloudflare).&nbsp;
          </Info>
        </li>
      </ul>
    </Flex>
  );
};
