import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Approver = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Un aprobador es la persona encargada de liberar y aprobar las órdenes de
        compra y solicitudes de pedido&nbsp; de insumos y servicios que se
        generan para los diferentes proyectos de una constructora.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
