import React from "react";
import { questions } from "../../utilities/questions";
import { Services } from "../../components/Services";

export const Buyers = () => {
  const buyerQuestions = questions.filter((ele) => ele.service === "comprador");

  return (
    <Services
      title={"Comprador"}
      message={
        "Aquí encontrarás todos los instructivos que necesitas como comprador."
      }
      questions={buyerQuestions}
    />
  );
};
