import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const AdjudicateQuotation = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <img
          alt="Cotizaciones"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/Cotizaciones.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Title>
      <Title>&nbsp;</Title>
      <Title>Adjudica una cotizaciones en 3 pasos.</Title>
      <Info>&nbsp;</Info>
      <Info>
        <em>
          Ten presente que para adjudicar una cotización debes estar registrado
          como <strong>Comprador</strong>&nbsp;y tener los permisos otorgados
          por parte del administrador para esta acción.
        </em>
      </Info>
      <Info>
        Para adjudicar una cotización debes ingresar a{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co">
          bimbau.co
        </a>{" "}
        con usuario y contraseña dirígerte a <strong>Administración</strong>{" "}
        desde tu perfil.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa3133ecff47e0017d2ea5dfile-MrFFOqENxs.png"
        width="185px"
      />
      <br></br>
      <br></br>
      <Info>
        En el&nbsp; módulo de <strong>Cotizaciones</strong>&nbsp;que encuentras
        en la barra lateral Izquierda. Escoge la cotización que quieras
        adjudicar y selecciona el botón de{" "}
        <strong>
          Editar.<br></br>
        </strong>
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5faef23046e0fb0017fcbd79file-G1xTqq7T4J.png"
        width={isMobile ? "100%" : "681px"}
      />{" "}
      <br></br>
      <br></br>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa31564cff47e0017d2ea69file-NipYbmMne5.png"
        width="181px"
      />
      <br></br>
      <br></br>
      <Info>
        Ve a la pestaña de <strong>Invitar Proveedores</strong> y en la sección
        de <strong>P</strong>
        <strong>roveedores Invitados</strong> que encuentras en la parte
        inferior verás la <strong>Propuesta económica </strong>de todos lo que
        participaron.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5faef24b4cedfd00165b03c2file-j7rJHh8oPR.png"
        width={isMobile ? "100%" : "681px"}
      />{" "}
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5fa3180d46e0fb0017fc8dbdfile-fiYrdfhLWe.png"
        width="188px"
      />
      <br></br>
      <br></br>
      <Info>
        En <strong> Acciones </strong>despliega las opciones y selecciona
        adjudicar al proveedor que más se ajuste a tu requerimiento y listo.
      </Info>
      <img
        alt=""
        src="https://f.hubspotusercontent40.net/hubfs/6957948/Knowledge%20Base%20Import/d33v4339jhl8k0.cloudfront.netdocsassets5f3c98852c7d3a352e910947images5faef6d14cedfd00165b03e1file-nklWx2TXHT.png"
        width={isMobile ? "100%" : "681px"}
      />
      <Title style={{ textAlign: "center" }}>
        De esa manera habrás adjudicado la cotización.
      </Title>
      <Info>&nbsp;</Info>
      <Info>
        <img
          alt="califica_este_articulo2-1"
          src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/califica_este_articulo2-1.jpg"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>&nbsp;</Info>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
