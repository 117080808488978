import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";

export const Zones = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Las zonas en BIMBAU permiten determinar geográficamente la cobertura y
        disponibilidad de los productos que serán cargados dentro de la
        plataforma.
      </Info>
      <p>&nbsp;</p>
    </Flex>
  );
};
