import React from "react";
import { useSelector } from "react-redux";
import { Flex, ServiceTitle } from "../../styles/common";

export const QuestionTitle = () => {
  const { common } = useSelector((state) => state);
  const { questionSelected } = common;

  return (
    <Flex m="0 0 1rem 0">
      <ServiceTitle>{questionSelected}</ServiceTitle>
    </Flex>
  );
};
