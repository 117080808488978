import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ItemsTender = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        Para conocer los ítems a ofertar dentro de una licitación, sigue estos
        pasos.
      </Title>
      <Info>
        <strong>
          <em>
            Ten en cuenta que para ofertar en una licitación debes estar
            registrado como VENDEDOR en BIMBAU.
          </em>
        </strong>
      </Info>
      <Info>
        1. Ingresa a{" "}
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co">
          bimbau.co
        </a>{" "}
        con usuario y contraseña.
      </Info>
      <Info>
        2. Dirígete al <strong> Licitaciones</strong> que encuentras en la barra
        superior.&nbsp;
      </Info>
      <Info>
        3. Escoge la licitación en la que quieres participar y ve a{" "}
        <strong>Ver licitación.</strong>
      </Info>
      <Info>
        4. Selecciona <strong>Estoy interesado&nbsp;</strong>y en la parte
        superior se habilitará la pestaña de<strong> Presentar oferta.</strong>
      </Info>
      <Info>
        4. Dirígete a <strong>Presentar oferta</strong> y en el{" "}
        <strong>paso 1</strong> descarga la plantillas de los ítems a cotizar.
      </Info>
      <Info2>
        De esa manera podrás ver los requerimientos de una licitación.&nbsp;
      </Info2>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
