import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info3, Title } from "../../styles/common";
import { theme } from "../../styles/theme";

export const CreateCoverage = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Title>
        <strong>
          <img
            alt="Portada3-V"
            src="https://6957948.fs1.hubspotusercontent-na1.net/hubfs/6957948/Portada3-V.jpg"
            width={isMobile ? "100%" : "688px"}
          />
        </strong>
      </Title>
      <Title>
        <strong>Crear Coberturas en BIMBAU es m</strong>
        <strong>
          uy fácil<br></br>
        </strong>
      </Title>
      <Info>
        Las Coberturas determinan geográficamente la zonas y disponibilidad de
        los productos y servicios que están en el catálogo de la plataforma.
      </Info>
      <ul>
        <li>
          <Info>
            <strong>Ingresa</strong> a{" "}
            <a
              style={{ color: theme.colors.secondary }}
              href="http://www.bimbau.co/"
            >
              bimbau.co
            </a>{" "}
            y accede con <strong>usuario y contraseña</strong>.
          </Info>
        </li>
        <li>
          <Info>
            Dirígete a la esquina superior derecha y selecciona{" "}
            <strong>Coberturas.</strong>
          </Info>
        </li>
        <li style={{ textAlign: "left" }}>
          <Info>
            Haz clic en <strong>Crear cobertura. </strong>
            <br></br>
          </Info>
        </li>
      </ul>
      <Info>
        Una vez dentro, escribe el
        <strong> Nombre de la cobertura</strong>
        <Info3>,</Info3> este nombre puede ayudarte a reconocer más fácilmente
        las zonas y disponibilidad de tus productos. Luego, asígnale las
        cobertura filtrando primero por los
        <strong> Departamentos y Estados</strong> y, después seleccionando las{" "}
        <strong>Ciudades o Provincias.</strong>
        <br></br>
        <br></br>
        <strong>📎TIPS: </strong>Ten en cuenta que puedes seleccionar todos los
        departamentos de tu país si tiene cobertura a nivel nacional. Al
        finalizar, seleccionar <strong>Crear cobertura </strong>
        <Info3>y</Info3> listo, ahora puedes ir a cargar tus productos y
        seleccionarles coberturas.
      </Info>
      <Info>
        &nbsp;Al crear la cobertura, BIMBAU te arrojará a un ID, es decir, un
        número de identificación que usarás luego para categorizar
        geográficamente tus productos.
      </Info>
      <br></br>
      <div>
        <img
          alt="como crear cobertura"
          src="https://f.hubspotusercontent40.net/hubfs/6957948/como%20crear%20cobertura.gif"
          width={isMobile ? "100%" : "688px"}
        />
      </div>
      <Title style={{ textAlign: "center" }}>
        ¡Para más detalles acerca de las coberturas mira este video!
      </Title>
      <Info>&nbsp;</Info>
      <div
        class="hs-embed-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          width: " 100%",
          height: "auto",
          padding: "0px",
          maxWidth: "688px",
          maxHeight: "388.72px",
          minWidth: "256px",
          display: "block",
          margin: "auto",
        }}
        data-service="youtube"
        data-responsive="true"
      >
        <div class="hs-embed-content-wrapper">
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              maxWidth: "100%",
              paddingBottom: "56.5%",
              margin: "0px",
            }}
          >
            <iframe
              title="create-coverage"
              width="200"
              height="113"
              src="https://www.youtube.com/embed/MpJOQ57SPNg?start=30&amp;feature=oembed"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
      <Info>&nbsp;</Info>
    </Flex>
  );
};
