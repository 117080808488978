import buyerIcon from "../assets/icons/iconos-02.png";
import vendorIcon from "../assets/icons/iconos-03.png";
import designerIcon from "../assets/icons/iconos-01.png";
import questionsIcon from "../assets/icons/iconos-08.png";
import glossaryIcon from "../assets/icons/iconos-04.png";
import instructivesIcon from "../assets/icons/iconos-05.png";

export const servicesButtons = [
  {
    img: buyerIcon,
    label: "Comprador",
    message:
      "Aquí encontrarás todos los instructivos que necesitas como comprador",
    link: "/comprador",
  },
  {
    img: vendorIcon,
    label: "Vendedor",
    message:
      "Aquí encontrarás todos los instructivos que necesitas como vendedor",
    link: "/vendedor",
  },
  {
    img: designerIcon,
    label: "Diseñador",
    message:
      "Aquí encontrarás todos los instructivos que necesitas como diseñador",
    link: "/diseñador",
  },
  {
    img: questionsIcon,
    label: "Preguntas frecuentes",
    message:
      "Encuentra aquí las preguntas más frecuentes que realizan los usuarios en BIMBAU",
    link: "/preguntas-frecuentes",
  },
  {
    img: glossaryIcon,
    label: "Glosario",
    message:
      "¿No conoces algún término de BIMBAU? Encuentra y consulta todas las palabras referentes a BIMBAU",
    link: "/glosario",
  },
  {
    img: instructivesIcon,
    label: "Instructivos generales",
    message:
      "¿Necesitas registrarte, actualizar documentos o actualizar roles? Descubre aquí todos los instructivos.",
    link: "/instructivos-generales",
  },
];
