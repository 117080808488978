import React from "react";
import { useMediaQuery } from "react-responsive";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ChangePrices = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Los precios que se encuentran en el catálogo de productos de BIMBAU
        corresponden a los precios de lista definidos por cada uno de los
        proveedores registrados en la plataforma.
      </Info>
      <Info>
        <img
          alt=""
          class="hs-image-align-none"
          src="https://s3.amazonaws.com/helpscout.net/docs/assets/5f3c98852c7d3a352e910947/images/60009cf32e764327f87bec5a/file-m5Zn8qnDG2.png"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
      <Info>
        Si tus precios varían&nbsp;constantemente, te recomendamos hacer la
        anotación en campo de&nbsp;
        <strong>
          <em>Condiciones comerciales</em>
        </strong>
        &nbsp;que encuentras al momento de cargar tus productos.
      </Info>
      <Info>
        <img
          alt=""
          class="hs-image-align-none"
          src="https://s3.amazonaws.com/helpscout.net/docs/assets/5f3c98852c7d3a352e910947/images/60009d18cfe30d219ccd7e12/file-lSOIdWcDra.png"
          width={isMobile ? "100%" : "688px"}
        />
      </Info>
    </Flex>
  );
};
