import React from "react";
import { questions } from "../../utilities/questions";
import { Services } from "../../components/Services";

export const Vendors = () => {
  const vendorsQuestions = questions.filter(
    (ele) => ele.service === "vendedor"
  );

  return (
    <Services
      title={"Vendedor"}
      message={
        "Aquí encontrarás todos los instructivos que necesitas como vendedor."
      }
      questions={vendorsQuestions}
    />
  );
};
