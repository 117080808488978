import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2 } from "../../styles/common";
import { theme } from "../../styles/theme";

export const ApproveAgreement = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        Las negociaciones de precio son aprobadas por los compradores. En&nbsp;
        <a style={{ color: theme.colors.secondary }} href="http://bimbau.co/">
          bimbau.co
        </a>{" "}
        cuando una negociación de precio es cargada por el{" "}
        <strong>Vendedor/proveedor</strong>&nbsp;se envía una solicitud de
        aprobación al <strong>Comprador</strong> y una vez aprobada la
        negociación se hace pública para la constructora.
      </Info>
      <Info2>Conectados, construimos más.</Info2>
    </Flex>
  );
};
