import React from "react";
import { QuestionTitle } from "../../components/QuestionTitle";
import { Flex, Info, Info2 } from "../../styles/common";

export const AgreementProducts = () => {
  return (
    <Flex direction="column">
      <QuestionTitle />
      <Info>
        En BIMBAU puedes comprar insumos y servicios sin que estén negociados
        previamente. Sin embargo, tener los requerimientos de un proyecto dentro
        de una negociación de precio te permite obtener un mejor valor y planear
        la logística y el abastecimiento de tus proyectos.&nbsp;
      </Info>
      <Info2>
        Ten en cuenta que si vas a comprar un producto fuera de una negociación
        de precios estará sujeta la compra a disponibilidad del vendedor.
      </Info2>
      <p>&nbsp;</p>
    </Flex>
  );
};
